import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL } from "../util/Constants";
import { EXCEL_FILE_TYPE } from "../util/Constants";
import { saveAs } from "file-saver";

class FileExchange {
  upload(data, path) {
    return axios.post(API_URL + path, data, {
      headers: authHeader(),
    });
  }

  download(path) {
    return axios
      .get(API_URL + path, {
        headers: authHeader(),
        responseType: "arraybuffer",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"]
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();

        const blob = new Blob([response.data], {
          type: EXCEL_FILE_TYPE,
        });

        saveAs(blob, filename);

        return response;
      });
  }
}

export default new FileExchange();
