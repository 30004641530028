import React, { useState } from "react";
import { Snackbar, Container, makeStyles } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import PasswordChangeComponent from "./PasswordChangeComponent";
import UserTable from "./UserTable";
import AuthService from "../../../services/Auth";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const UserMaster = () => {
  const classes = useStyles();

  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  return (
    <div>
      <Container component="main" maxWidth="md" className={classes.root}>
        <PasswordChangeComponent
          setsnackbarOpen={setsnackbarOpen}
          setsnackbarSeverity={setsnackbarSeverity}
          setsnackbarMessage={setsnackbarMessage}
        />
        {AuthService.isAdmin() && (
          <UserTable
            setsnackbarOpen={setsnackbarOpen}
            setsnackbarSeverity={setsnackbarSeverity}
            setsnackbarMessage={setsnackbarMessage}
          />
        )}
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserMaster;
