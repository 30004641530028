import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CartIcon from "@material-ui/icons/ShoppingCart";
import AssignOrders from "@material-ui/icons/SyncAlt";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { printDate } from "../client-orders/OrdersTable";
import { printState } from "../client-orders/OrdersTable";
import ShipmentRequest from "../../../services/ShipmentRequest";

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: window.screen.height - 430,
    width: "100%",
  },
});

const HeaderTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 14,
  },
}))(TableCell);

const titles = [
  "",
  "Id",
  "Nombre",
  "Fecha",
  "Ciudades",
  "# Pedidos",
  "Estado",
  "",
];

export const getShipmentCities = (row) => {
  const cities = [];
  row.zones.forEach((zone) => {
    if (!cities.includes(zone.city)) {
      cities.push(zone.city);
    }
  });

  return cities.join(", ");
};

function ShipmentsTable(props) {
  const {
    dataSelected,
    data,
    setData,
    handleOpenEditShipment,
    handleOpenAssignOrders,
    handleOpenConfirmShipment,
  } = props;

  const classes = useStyles();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(-1);

  const handleDeleteShipment = (row) => {
    ShipmentRequest.deleteShipmentById(row.id).then((response) => {
      if (response.status === 200) {
        const updatedData = [...data];
        updatedData.splice(updatedData.indexOf(row), 1);
        setData(updatedData);
      }
    });
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} stickyHeader>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {titles.map((title, index) => (
              <HeaderTableCell key={index} align="right">
                {title}
              </HeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSelected.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell
                align="right"
                style={{ width: 100 }}
                padding="checkbox"
              >
                {/* {row.state === "PENDING" && ( */}
                <Tooltip title="Confirmar Despacho">
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => handleOpenConfirmShipment(row)}
                    >
                      <CartIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {/* )} */}
                {/* {row.state === "PENDING" && ( */}
                <Tooltip title="Editar Despacho">
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => handleOpenEditShipment(index)}
                      disabled={row.state !== "PENDING"}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {/* )} */}
                <Tooltip title="Asignar Pedidos">
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => handleOpenAssignOrders(index)}
                      disabled={row.state !== "PENDING"}
                    >
                      <AssignOrders />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">{printDate(row.shippingDate)}</TableCell>
              <TableCell align="right">{getShipmentCities(row)}</TableCell>
              <TableCell align="right">{row.customerOrders.length}</TableCell>
              <TableCell align="right">{printState(row.state)}</TableCell>
              <TableCell align="right" padding="checkbox">
                {row.state == "PENDING" ? (
                  <IconButton
                    onClick={() => {
                      setOpenDeleteDialog(index);
                    }}
                    size="small"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                ) : null}
                <Dialog
                  open={index === openDeleteDialog ? true : false}
                  onClose={() => setOpenDeleteDialog(-1)}
                >
                  <div style={{ padding: 20 }}>
                    <DialogTitle>{`Eliminar el despacho ${row.id}?`}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Una vez eliminado no se podrá recuperar
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleDeleteShipment(row);
                          setOpenDeleteDialog(-1);
                        }}
                      >
                        Eliminar
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => setOpenDeleteDialog(-1)}
                      >
                        Cancelar
                      </Button>
                    </DialogActions>
                  </div>
                </Dialog>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ShipmentsTable;
