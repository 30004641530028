import React from "react";
import { Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "../../shared/ProtectedRoute";
import AdminProtectedRoute from "../../shared/AdminProtectedRoute";
import Dashboard from "./dashboard/Dashboard";
import ClientMaster from "./client-master/ClientMaster";
import ProductMaster from "./product-master/ProductMaster";
import ClientOrders from "./client-orders/ClientOrders";
import Operation from "./operation/Operation";
import Sales from "./sales/Sales";
import Stock from "./stock/Stock";
import CollectionsReport from "./collections-report/index";
import SupplierMaster from "./supplier-master/SupplierMaster";
import SupplierOrders from "./supplier-orders/SupplierOrders";
import UserMaster from "./user-master/UserMaster";
import ZoneMaster from "./zone-master/ZoneMaster";

const Routing = (props) => {
  return (
    <div>
      <Switch>
        <ProtectedRoute path={"/main/client-orders"} component={ClientOrders} />
        <ProtectedRoute
          path={"/main/supplier-orders"}
          component={SupplierOrders}
        />
        <ProtectedRoute path={"/main/operation"} component={Operation} />
        <AdminProtectedRoute path={"/main/sales"} component={Sales} />
        <AdminProtectedRoute path={"/main/stock"} component={Stock} />
        <ProtectedRoute path={"/main/client-master"} component={ClientMaster} />
        <ProtectedRoute
          path={"/main/product-master"}
          component={ProductMaster}
        />
        <ProtectedRoute
          path={"/main/supplier-master"}
          component={SupplierMaster}
        />
        <ProtectedRoute
          path={"/main/collections-report"}
          component={CollectionsReport}
        />
        <ProtectedRoute path={"/main/zone-master"} component={ZoneMaster} />
        <ProtectedRoute path={"/main/user-master"} component={UserMaster} />
        <ProtectedRoute
          path={["/main", "/main/dashboard"]}
          component={Dashboard}
        />
      </Switch>
    </div>
  );
};

export default Routing;
