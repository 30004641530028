import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  List,
  ListItem,
  Paper,
  TextField,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Backup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import ShipmentRequest from "../../../services/ShipmentRequest";

const useStyles = makeStyles((theme) => ({
  dateSelector: {
    width: 140,
  },

  selector: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 250,
  },

  list: {
    height: 170,
    width: 200,
    overflow: "auto",
    fontSize: 14,
  },

  listItemText: {
    marginLeft: 0,
  },

  selectList: {
    maxHeight: 200,
    overflow: "auto",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditShipment(props) {
  const classes = useStyles();
  const { openEditShipment, data, setData, setOpenEditShipment, zones } = props;

  const [name, setName] = useState("");
  const [dateSelected, setDateSelected] = useState(new Date());
  const [citySelected, setCitySelected] = useState("");
  const [zonesSelected, setZonesSelected] = useState([]);

  var cityOptions = [];
  var neighborhoodOptions = [];
  zones.forEach((zone) => {
    if (!cityOptions.includes(zone.city)) {
      cityOptions.push(zone.city);
    }

    if (zone.city === citySelected) {
      neighborhoodOptions.push(zone.neighborhood);
    }
  });

  useEffect(() => {
    if (openEditShipment > -1) {
      setName(data[openEditShipment].name);
      setDateSelected(new Date(data[openEditShipment].shippingDate));
      setZonesSelected(data[openEditShipment].zones);
    } else {
      setName("");
      setDateSelected(new Date());
      setCitySelected("");
      setZonesSelected([]);
    }
  }, [openEditShipment]);

  const handleCityChange = (event) => {
    setCitySelected(event.target.value);
  };

  const handleZonesSelected = (event) => {
    const updatedZonesSelected = [...zonesSelected];
    const foundIndex = zonesSelected.findIndex(
      (zone) => zone.neighborhood === event.target.value.slice(-1).toString()
    );

    if (foundIndex !== -1) {
      updatedZonesSelected.splice(foundIndex, 1);
    } else {
      updatedZonesSelected.push(
        zones.find(
          (zone) =>
            zone.neighborhood === event.target.value.slice(-1).toString()
        )
      );
    }

    setZonesSelected(updatedZonesSelected);
  };

  const isIncluded = (option) => {
    for (const zone of zonesSelected) {
      if (zone.neighborhood === option) {
        return true;
      }
    }
    return false;
  };

  const handleSaveChanges = () => {
    let modifiedShipment = {};
    if (openEditShipment === -1) {
      modifiedShipment = {
        name: name,
        shippingDate: dateSelected,
        state: "PENDING",
        zones: zonesSelected,
      };
    } else {
      modifiedShipment = data[openEditShipment];
      modifiedShipment.name = name;
      modifiedShipment.shippingDate = dateSelected;
      modifiedShipment.zones = zonesSelected;
    }

    ShipmentRequest.modifyShipmentData(modifiedShipment).then((response) => {
      if (response.status === 200) {
        const modifiedShipment = response.data;
        const updatedData = [...data];
        if (openEditShipment === -1) {
          updatedData[updatedData.length] = modifiedShipment;
        } else {
          updatedData[openEditShipment] = modifiedShipment;
        }
        setData(updatedData);
      }
    });

    handleClose();
  };

  const handleClose = () => {
    setOpenEditShipment(-2);
  };

  const handleSelectAllNeighborhoods = () => {
    const updatedZonesSelected = [...zonesSelected];

    zones.forEach((zone) => {
      if (zone.city === citySelected && !updatedZonesSelected.includes(zone)) {
        updatedZonesSelected.push(zone);
      }
    });

    setZonesSelected(updatedZonesSelected);
  };

  return (
    <Dialog
      open={openEditShipment >= -1 ? true : false}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <div style={{ padding: 20 }}>
        <Grid container spacing={4} direction="column">
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={6}
              justify="flex-start"
              alignItems="center"
            >
              <DialogTitle>
                {openEditShipment > -1
                  ? "Edicion de Despacho: ".concat(data[openEditShipment].id)
                  : "Nuevo Despacho"}
              </DialogTitle>
              <TextField
                name="shipmentName"
                value={name}
                size="large"
                align="left"
                type="text"
                label="Nombre"
                onChange={(event) => setName(event.target.value)}
                style={{ width: 200 }}
                InputProps={{
                  style: { fontSize: 16 },
                  inputProps: {
                    style: { textAlign: "left" },
                  },
                }}
              />
            </Grid>
            <Grid container item xs={3} justify="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSelectAllNeighborhoods}
                disabled={!(citySelected.length > 0)}
              >
                seleccionar todos
              </Button>
            </Grid>
            <Grid container item xs={3} justify="flex-end">
              <Button
                color="primary"
                variant="contained"
                endIcon={<SaveIcon />}
                onClick={handleSaveChanges}
                disabled={!(zonesSelected.length > 0)}
              >
                Guardar
                <br />
                Cambios
              </Button>
            </Grid>
          </Grid>
          <Divider />
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={6}
              direction="column"
              alignItems="center"
              justify="flex-start"
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.dateSelector}
                  value={dateSelected}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="none"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={setDateSelected}
                />
              </MuiPickersUtilsProvider>
              <FormControl className={classes.selector} margin="dense">
                <InputLabel>Ciudad</InputLabel>
                <Select
                  value={citySelected}
                  onChange={handleCityChange}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {cityOptions.map((city) => (
                    <MenuItem key={city} value={city}>
                      <ListItemText primary={city} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.selector}>
                <InputLabel>Barrio</InputLabel>
                <Select
                  value={zonesSelected}
                  onChange={handleZonesSelected}
                  input={<Input />}
                  multiple
                  renderValue={() => ""}
                  disabled={citySelected === ""}
                  MenuProps={MenuProps}
                >
                  {neighborhoodOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={isIncluded(option)} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={6}>
              <List
                className={classes.list}
                component={Paper}
                dense
                disablePadding
                style={{}}
              >
                {zonesSelected.map((zone) => (
                  <ListItem key={zone.neighborhood} button>
                    <ListItemText className={classes.listItemText}>
                      {zone.neighborhood}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
export default EditShipment;
