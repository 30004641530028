export const getTotalAmount = (customerOrder) => {
  let totalAmount = 0;

  for (const detail of customerOrder.customerOrderDetailsList) {
    totalAmount += Number(detail.deliveredPrice);
  }

  totalAmount += customerOrder.deliveryPrice;

  return totalAmount;
};

export const getProductSellPrice = (product, customerOrder) => {
  return product.productSellPrices.find(
    (productSellPrice) =>
      productSellPrice.customerType.description ==
      customerOrder.customer.customerType.description
  ).sellPrice;
};
