import axios from "axios";
import mapboxgl from "mapbox-gl";
import authHeader from "./AuthHeader";
import { API_URL } from "../util/Constants";

const mapboxAPI = "https://api.mapbox.com/";
const mapboxDirectionsAPI = mapboxAPI + "directions/v5/mapbox/driving/";

const pointsLimitPerRequest = 23;

class MapboxServices {
  getMapboxToken() {
    return axios.get(API_URL + "token/mapbox", {
      headers: authHeader(),
    });
  }

  async getDirections(mapData) {
    const points = [];

    mapData.features.forEach((waypoint) =>
      points.push([
        waypoint.geometry.coordinates[0],
        waypoint.geometry.coordinates[1],
      ])
    );

    let completeResponse;

    const batches = Math.ceil(points.length / pointsLimitPerRequest);

    for (let i = 0; i < batches; i++) {
      const pointsBatch = points.slice(
        Math.max(i * pointsLimitPerRequest - 1, 0),
        Math.min((i + 1) * pointsLimitPerRequest, points.length)
      );

      const processedPoints = pointsBatch.join(";");

      let response = await axios.get(mapboxDirectionsAPI + processedPoints, {
        params: {
          geometries: "geojson",
          access_token: mapboxgl.accessToken,
        },
      });

      if (i === 0) {
        completeResponse = response;
      } else {
        completeResponse.data.routes[0].geometry.coordinates = completeResponse.data.routes[0].geometry.coordinates.concat(
          response.data.routes[0].geometry.coordinates
        );

        completeResponse.data.routes[0].legs = completeResponse.data.routes[0].legs.concat(
          response.data.routes[0].legs
        );
      }
    }

    return completeResponse;
  }
}

export default new MapboxServices();
