import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL } from "../util/Constants";

const path = "zone";

class ZoneRequest {
  getData() {
    return axios.get(API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new ZoneRequest();
