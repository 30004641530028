const stateToString = (state) => {
  if (state === "PENDING") {
    return "Pendiente";
  } else if (state === "COMPLETED") {
    return "Completado";
  } else if (state === "CANCELLED") {
    return "Cancelado";
  }
};

export default stateToString;
