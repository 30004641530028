import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const SalesChart = (props) => {
  const { selectedProducts, selectedCustomers, salesRecords } = props;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const data = [];
    salesRecords.forEach((salesRecord) => {
      const selectedProductIndex = selectedProducts.findIndex(
        (reg) => reg.description === salesRecord.productDescription
      );

      if (selectedProductIndex < 0) {
        return;
      }

      const selectedCustomerIndex = selectedCustomers.findIndex(
        (reg) => reg.nameAndSurname === salesRecord.customerName
      );

      if (selectedCustomerIndex < 0) {
        return;
      }

      const dataRecordIndex = data.findIndex(
        (reg) => reg.date === salesRecord.date
      );

      if (dataRecordIndex >= 0) {
        data[dataRecordIndex].amount += salesRecord.amount;
      } else {
        data.push({
          date: salesRecord.date,
          amount: salesRecord.amount,
        });
      }

      data.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
    });

    setChartData(data);
  }, [selectedProducts, selectedCustomers, salesRecords]);

  return (
    <Paper>
      <Typography align="center">Ventas por día [$]</Typography>
      <BarChart
        width={600}
        height={400}
        data={chartData}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="amount" fill="#00923F" />
      </BarChart>
    </Paper>
  );
};

export default SalesChart;
