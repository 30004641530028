import React, { useEffect, useState } from "react";
import { makeStyles, Snackbar, Grid, Divider } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ProductRequest from "../../../services/ProductRequest";
import DateFilter from "../../../shared/Filters/DateFilter";
import ProductTable from "../../../shared/ProductTable";
import StockChart from "./StockChart";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: { margin: "30px" },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const getDefaultStartDate = () => {
  let date = new Date();
  date.setDate(date.getDate() - 31);
  date.setHours(0, 0, 0, 0);
  return date;
};

const Stock = () => {
  const classes = useStyles();

  const [products, setProducts] = useState([]);
  const [stockRecords, setStockRecords] = useState([]);

  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(new Date());

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  useEffect(() => {
    fetchProducts();
    fetchStockRecords();
  }, []);

  useEffect(() => {
    fetchStockRecords();
  }, [startDate, endDate]);

  const fetchProducts = async () => {
    let response;
    try {
      response = await ProductRequest.getData();
      setProducts(response.data);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const fetchStockRecords = async () => {
    let inclusiveEndDate = new Date(endDate);
    inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

    let response;
    try {
      response = await ProductRequest.getStockRecords(
        startDate,
        inclusiveEndDate
      );
      setStockRecords(response.data);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  return (
    <div>
      <Grid container className={classes.root} justify="space-between">
        <Grid
          container
          className={classes.root}
          spacing={2}
          direction="column"
          xs={12}
          sm={4}
        >
          <Grid
            container
            className={classes.root}
            justify="space-evenly"
            alignItems="flex-start"
          >
            <DateFilter
              label="Desde"
              value={startDate}
              handleChangeValue={setStartDate}
            />
            <DateFilter
              label="Hasta"
              value={endDate}
              handleChangeValue={setEndDate}
            />
          </Grid>

          <Divider variant="middle" flexItem className={classes.divider} />
          <Grid container className={classes.root} spacing={2}>
            <ProductTable
              products={products}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.root}
          spacing={2}
          xs={8}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <StockChart
              selectedProducts={selectedProducts}
              stockRecords={stockRecords}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Stock;
