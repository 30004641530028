import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { printDate } from "../../client-orders/OrdersTable";

const titles = ["ID", "Barrio", "Fecha", "Cliente"];

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: window.screen.height - 520,
  },
});

const HeaderTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 14,
  },
}))(TableCell);

function AssignOrdersTable(props) {
  const { orders, ordersSelected, setOrdersSelected, checkColor } = props;
  const classes = useStyles();

  const handleCheckChange = (row) => {
    if (ordersSelected.includes(row)) {
      const updatedOrdersSelected = ordersSelected.slice();
      updatedOrdersSelected.splice(updatedOrdersSelected.indexOf(row), 1);
      setOrdersSelected(updatedOrdersSelected);
    } else if (!ordersSelected.includes(row)) {
      const updatedOrdersSelected = ordersSelected.slice();
      updatedOrdersSelected.push(row);
      setOrdersSelected(updatedOrdersSelected);
    }
  };

  const handleCheckAllRows = () => {
    if (ordersSelected.length === orders.length) {
      setOrdersSelected([]);
    } else {
      setOrdersSelected(orders);
    }
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table stickyHeader>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <HeaderTableCell padding="checkbox">
              <Checkbox
                checked={
                  ordersSelected.length === orders.length && orders.length > 0
                }
                onChange={handleCheckAllRows}
                color={checkColor}
              />
            </HeaderTableCell>
            {titles.map((title, index) => (
              <HeaderTableCell key={index} align="left">
                {title}
              </HeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow key={row.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={ordersSelected.includes(row)}
                  onChange={() => handleCheckChange(row)}
                  color={checkColor}
                />
              </TableCell>
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">
                {row.customer.zone.neighborhood}
              </TableCell>
              <TableCell align="left">{printDate(row.requestDate)}</TableCell>
              <TableCell align="left">{row.customer.nameAndSurname}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default AssignOrdersTable;
