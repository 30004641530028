import React, { useState, useEffect } from "react";
import {
  Grid,
  Divider,
  Button,
  AppBar,
  Toolbar,
  Slide,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import SimpleFilter from "../../../../shared/Filters/SimpleFilter";
import ProductRequest from "../../../../services/ProductRequest";
import StockForecastTable from "./StockForecastTable";
import SupplierOrderRequest from "../../../../services/SupplierOrderRequest";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },

  input: {
    display: "none",
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

function getSelectedStockForecastValues(
  stockForecastList,
  selectedProductCategories
) {
  let selectedStockForecastValues = stockForecastList;

  //Product Category Filter
  if (selectedProductCategories.length > 0) {
    selectedStockForecastValues = selectedStockForecastValues.filter(
      (stockForecast) => {
        if (selectedProductCategories.includes(stockForecast.productCategory)) {
          return true;
        }
        return false;
      }
    );
  }

  return selectedStockForecastValues;
}

function SupplierOrderCreation(props) {
  const {
    openCreateOrders,
    setOpenCreateOrders,
    fetchOrders,
    showSnackbarSuccess,
    showSnackbarError,
  } = props;
  const classes = useStyles();

  const [stockForecastList, setStockForecastList] = useState([]);
  const [productCategoryOptions, setProductCategoryOptions] = useState([]);

  const [selectedProductCategories, setSelectedProductCategories] = useState(
    []
  );
  const [requestedQuantities, setRequestedQuantities] = useState([]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    fetchStockForecast();
  }, []);

  const fetchStockForecast = async () => {
    try {
      const response = await ProductRequest.getStockForecast();

      if (response.data !== undefined) {
        setStockForecastList(response.data);

        let auxProductCategoryOptions = [];

        response.data.forEach((stockForecast) => {
          if (
            !auxProductCategoryOptions.includes(stockForecast.productCategory)
          ) {
            auxProductCategoryOptions.push(stockForecast.productCategory);
          }
        });

        auxProductCategoryOptions.sort();

        setProductCategoryOptions(auxProductCategoryOptions);
        setRequestedQuantities(new Array(response.data.length));
      }
    } catch (error) {
      showSnackbarError(error);
    }
  };

  const handleProductCategoryChange = (event) => {
    setSelectedProductCategories(event.target.value);
  };

  const handleClearFilters = () => {
    setSelectedProductCategories([]);
  };

  const handleCloseComponent = () => {
    fetchOrders();
    setOpenCreateOrders(false);
  };

  const generateOrdersHandler = async () => {
    let ordersToCreateList = [];
    for (let i = 0; i < stockForecastList.length; i++) {
      if (
        requestedQuantities[i] === undefined ||
        requestedQuantities[i] === 0
      ) {
        continue;
      }

      let orderToCreate = {
        productId: stockForecastList[i].productId,
        quantity: requestedQuantities[i],
      };

      ordersToCreateList.push(orderToCreate);
    }

    if (ordersToCreateList.length === 0) {
      return;
    }

    try {
      const response = await SupplierOrderRequest.post(ordersToCreateList);
      handleCloseComponent();
      showSnackbarSuccess();
    } catch (error) {
      showSnackbarError(error);
    }
  };

  const handleRequestedQuantityChange = (event, productId) => {
    event.persist();

    setRequestedQuantities((previousState) => {
      let updatedQuantities = [...previousState];
      const index = stockForecastList.findIndex(
        (reg) => reg.productId === productId
      );
      updatedQuantities[index] = Number(event.target.value);
      return updatedQuantities;
    });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={openCreateOrders}
        onClose={handleCloseComponent}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.AppBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseComponent}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Nuevos pedidos
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container style={{ marginTop: 30, padding: 40 }} spacing={2}>
          <Grid container item justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              onClick={() => setOpenConfirmDialog(true)}
            >
              Generar Pedidos
            </Button>
          </Grid>
          <Divider />
          <Grid
            container
            item
            xs={9}
            justify="space-evenly"
            alignItems="center"
          >
            <SimpleFilter
              label="Categoría"
              value={selectedProductCategories}
              onChange={handleProductCategoryChange}
              filterOptions={productCategoryOptions}
              selectedOptions={selectedProductCategories}
            />
            <Button
              id="ClearFilters"
              variant="text"
              color="primary"
              onClick={handleClearFilters}
            >
              Borrar Filtros
            </Button>
          </Grid>
          <Grid item xs={12}>
            {requestedQuantities.length > 0 && (
              <StockForecastTable
                stockForecastList={getSelectedStockForecastValues(
                  stockForecastList,
                  selectedProductCategories
                )}
                requestedQuantities={requestedQuantities}
                handleRequestedQuantityChange={handleRequestedQuantityChange}
              />
            )}
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <div style={{ padding: 20 }}>
          <DialogTitle>{`¿Confirmar la creación de pedidos?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Una vez confirmado se emitirán los pedidos a los proveedores
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={generateOrdersHandler}>
              Confirmar
            </Button>
            <Button color="primary" onClick={() => setOpenConfirmDialog(false)}>
              Volver
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default SupplierOrderCreation;
