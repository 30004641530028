import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    width: 120,
  },
}));

function StateFilter(props) {
  const { label, selectedOptions, filterOptions, ...allProps } = props;
  const classes = useStyles();

  const getPrintOption = (option) => {
    if (option === "PENDING") {
      return "Pendiente";
    } else if (option === "COMPLETED") {
      return "Completado";
    } else if (option === "CANCELLED") {
      return "Cancelado";
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...allProps}
        input={<Input />}
        multiple
        renderValue={(selected) => {
          const printOptions = [];
          for (const option of selected) {
            printOptions.push(getPrintOption(option));
          }
          return printOptions.join(", ");
        }}
      >
        {filterOptions.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.includes(option)} />
            <ListItemText primary={getPrintOption(option)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default StateFilter;
