import React, { useState, useEffect } from "react";
import { Grid, Divider, Button, Snackbar, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UploadIcon from "@material-ui/icons/Publish";
import OrdersTable from "./OrdersTable";
import { EXCEL_FILE_TYPE } from "../../../util/Constants";
import FileExchange from "../../../services/FileExchange";
import SimpleFilter, {
  getSelectSimpleOptions,
  getSelectInnerOptions,
} from "../../../shared/Filters/SimpleFilter";
import DateFilter from "../../../shared/Filters/DateFilter";
import Alert from "@material-ui/lab/Alert";
import CustomerOrdersRequest from "../../../services/CustomerOrdersRequest";
import StateFilter from "../../../shared/Filters/StateFilter";
import ProductRequest from "../../../services/ProductRequest";
import OrderDetails from "./OrderDetails";
import CustomerOrdersPerProduct from "./CustomerOrdersPerProduct";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },

  input: {
    display: "none",
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

function getDataSelected(
  data,
  startDate,
  endDate,
  neighborhoodsSelected,
  statesSelected,
  articlesSelected,
  clientNameSearched
) {
  var dataSelected = data;

  //Start Date Filter
  dataSelected = dataSelected.filter(
    (row) => new Date(row.requestDate) >= startDate
  );

  //End Date Filter
  let inclusiveEndDate = new Date(endDate);
  inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

  dataSelected = dataSelected.filter(
    (row) => new Date(row.requestDate) <= inclusiveEndDate
  );

  //Neighborhoods Filter
  if (neighborhoodsSelected.length > 0) {
    dataSelected = dataSelected.filter((row) =>
      neighborhoodsSelected.includes(row.customer.zone.neighborhood)
    );
  }

  //State Filter
  if (statesSelected.length > 0) {
    dataSelected = dataSelected.filter((row) =>
      statesSelected.includes(row.state)
    );
  }

  //Article Filter
  if (articlesSelected.length > 0) {
    dataSelected = dataSelected.filter((row) => {
      for (const detail of row.customerOrderDetailsList) {
        if (articlesSelected.includes(detail.product.description)) {
          return true;
        }
      }
      return false;
    });
  }

  //client name filter
  if (
    clientNameSearched != null &&
    clientNameSearched != undefined &&
    clientNameSearched != ""
  ) {
    dataSelected = dataSelected.filter((customerOrder) => {
      if (
        customerOrder.customer.nameAndSurname
          .toUpperCase()
          .includes(clientNameSearched.toUpperCase())
      ) {
        return true;
      }
    });
  }

  return dataSelected;
}

export const initialFromDate = new Date();
initialFromDate.setDate(initialFromDate.getDate() - 7);
initialFromDate.setHours(0, 0, 0, 0);

export const initialToDate = new Date();
initialToDate.setHours(23, 59, 59, 99);

export var productList = [];

function ClientOrders() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(initialFromDate);
  const [endDate, setEndDate] = useState(new Date());
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([]);
  const [neighborhoodsSelected, setNeighboroodsSelected] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [statesSelected, setStatesSelected] = useState([]);
  const [articlesOptions, setArticlesOptions] = useState([]);
  const [articlesSelected, setArticleSelected] = useState([]);
  const [openDetail, setOpenDetail] = React.useState(-1);
  const [clientNameSearched, setClientNameSearched] = useState(undefined);
  const [openCOPP, setOpenCOPP] = useState(false);

  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  useEffect(() => {
    ProductRequest.getData().then((response) => {
      productList = response.data;
    });
  }, []);

  useEffect(() => {
    const inclusiveEndDate = new Date(endDate);
    inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

    CustomerOrdersRequest.getData(startDate, inclusiveEndDate).then(
      (response) => {
        setData(response.data);
        setNeighborhoodsOptions(
          getSelectSimpleOptions(response.data, "customer.zone.neighborhood")
        );
        setArticlesOptions(
          getSelectInnerOptions(
            response.data,
            "customerOrderDetailsList",
            "product.description"
          )
        );
        setStateOptions(getSelectSimpleOptions(response.data, "state"));
        setStatesSelected(["PENDING"]);
      }
    );
  }, [startDate, endDate]);

  const handleNeighborhoodChange = (event) => {
    setNeighboroodsSelected(event.target.value);
  };

  const handleStateChange = (event) => {
    setStatesSelected(event.target.value);
  };

  const handleArticleChange = (event) => {
    setArticleSelected(event.target.value);
  };

  const handleClientNameSearchedChange = (event) => {
    setClientNameSearched(event.target.value);
  };

  const handleClearFilters = () => {
    setNeighboroodsSelected([]);
    setStatesSelected([]);
    setArticleSelected([]);
    setClientNameSearched("");
  };

  const uploadHandler = (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);

    event.target.value = null; //asi permite subir archivos consecutivamente

    const path = "customerOrder/upload";
    FileExchange.upload(data, path)
      .then((response) => {
        setsnackbarMessage(response.data.message);
        setsnackbarSeverity("success");
        setsnackbarOpen(true);

        CustomerOrdersRequest.getData(startDate, endDate).then((response) => {
          setData(response.data);
        });
      })
      .catch((error) => {
        setsnackbarMessage("" + error + " - " + error.response.data.message);
        setsnackbarSeverity("error");
        setsnackbarOpen(true);
      });
  };

  const openCOPPHandler = () => {
    setOpenCOPP(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  return (
    <Grid container spacing={4} direction="column">
      <Grid container item justify="flex-end">
        <div>
          <input
            accept={[EXCEL_FILE_TYPE]}
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={uploadHandler}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="secondary"
              component="span"
              className={classes.button}
              endIcon={<UploadIcon />}
            >
              subir
              <br />
              pedidos
            </Button>
          </label>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={10000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          onClick={openCOPPHandler}
        >
          pedidos por
          <br />
          artículo
        </Button>
      </Grid>
      <Divider />
      <Grid container item xs={12} justify="space-evenly" alignItems="center">
        <TextField
          variant="outlined"
          margin="normal"
          name="client"
          label="Cliente"
          type="client"
          id="client"
          value={clientNameSearched}
          onChange={handleClientNameSearchedChange}
        />
        <DateFilter
          label="Desde"
          value={startDate}
          handleChangeValue={setStartDate}
        />
        <DateFilter
          label="Hasta"
          value={endDate}
          handleChangeValue={setEndDate}
        />
        <SimpleFilter
          label="Articulo"
          value={articlesSelected}
          onChange={handleArticleChange}
          filterOptions={articlesOptions}
          selectedOptions={articlesSelected}
        />
        <StateFilter
          label="Estado"
          name="state"
          value={statesSelected}
          onChange={handleStateChange}
          filterOptions={stateOptions}
          selectedOptions={statesSelected}
        />
        <SimpleFilter
          label="Barrio"
          name="customer.zone.neighborhood"
          value={neighborhoodsSelected}
          onChange={handleNeighborhoodChange}
          filterOptions={neighborhoodsOptions}
          selectedOptions={neighborhoodsSelected}
        />
        <Button
          id="ClearFilters"
          variant="text"
          color="primary"
          onClick={handleClearFilters}
        >
          Borrar Filtros
        </Button>
      </Grid>
      <Grid item xs={12}>
        <OrdersTable
          dataSelected={getDataSelected(
            data,
            startDate,
            endDate,
            neighborhoodsSelected,
            statesSelected,
            articlesSelected,
            clientNameSearched
          )}
          setOpenDetail={setOpenDetail}
        />
        {openDetail >= 0 && (
          <OrderDetails
            openDetail={openDetail}
            setOpenDetail={setOpenDetail}
            data={getDataSelected(
              data,
              startDate,
              endDate,
              neighborhoodsSelected,
              statesSelected,
              articlesSelected,
              clientNameSearched
            )}
            setData={setData}
          />
        )}
        <CustomerOrdersPerProduct
          openCOPP={openCOPP}
          setOpenCOPP={setOpenCOPP}
          productList={productList}
        />
      </Grid>
    </Grid>
  );
}

export default ClientOrders;
