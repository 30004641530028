import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

export const getSelectSimpleOptions = (data, fieldName) => {
  const options = [];

  for (const row of data) {
    const neighborhood = eval("row." + fieldName)
    if (!options.includes(neighborhood)) {
      options.push(neighborhood);
    }
  }
  return options;
};

export const getSelectInnerOptions = (data, fieldName, innerFieldName) => {
  const options = [];

  for (const row of data) {
    for (const detail of eval("row." + fieldName)) {
      const currentOption = eval("detail." + innerFieldName)
      if (!options.includes(currentOption)) {
        options.push(currentOption);
      }
    }
  }
  return options.sort();
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    width: 120
  },

  menuPaper: {
    maxHeight: 200
  }
}));

function SimpleFilter(props) {
  const { label, filterOptions, selectedOptions, ...allProps } = props;
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...allProps}
        input={<Input />}
        multiple
        renderValue={(selected) => selected.join(", ")}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        {filterOptions.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default SimpleFilter;
