import axios from "axios";
import { API_URL } from "../util/Constants";
import authHeader from "./AuthHeader";

const BASE_URL = API_URL + "auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(BASE_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password, roles) {
    return axios.post(
      BASE_URL + "signup",
      {
        username,
        email,
        password,
        roles,
      },
      {
        headers: authHeader(),
      }
    );
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  isTokenExpired() {
    if (this.getCurrentUser()) {
      const tokenExpirationDate = JSON.parse(
        atob(this.getCurrentUser().accessToken.split(".")[1])
      ).exp;

      const tokenExpirationDateMinusOneHour =
        (tokenExpirationDate - 3600) * 1000;

      if (Date.now() < tokenExpirationDateMinusOneHour) {
        return false;
      }
    }

    return true;
  }

  isAdmin() {
    const currentUser = this.getCurrentUser();

    if (currentUser) {
      for (let role of currentUser.roles) {
        if (role.includes("ADMIN")) {
          return true;
        }
      }
    }

    return false;
  }

  changePassword(passwordChangeRequest) {
    return axios.put(BASE_URL + "change-password", passwordChangeRequest, {
      headers: authHeader(),
    });
  }

  getUsers() {
    return axios.get(BASE_URL + "users", {
      headers: authHeader(),
    });
  }

  getRoles() {
    return axios.get(BASE_URL + "roles", {
      headers: authHeader(),
    });
  }

  modifyUser(userData) {
    return axios.put(BASE_URL + "modify", userData, {
      headers: authHeader(),
    });
  }

  deleteUser(id) {
    return axios.delete(BASE_URL + id, {
      headers: authHeader(),
    });
  }
}

export default new AuthService();
