import React, { useState, useEffect } from "react";
import {
  Dialog,
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  Button,
  Grid,
  Divider,
  Box,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { printDate } from "../../client-orders/OrdersTable";
import SimpleFilter, {
  getSelectSimpleOptions,
} from "../../../../shared/Filters/SimpleFilter";
import AssignOrdersTable from "./AssignOrdersTable";
import AltRouteIcon from "@material-ui/icons/CallSplit";
import ArrowRightIcon from "@material-ui/icons/ArrowForward";
import ArrowLeftIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import CustomerOrdersRequest from "../../../../services/CustomerOrdersRequest";
import { getShipmentCities } from "../ShipmentsTable";
import ShipmentRequest from "../../../../services/ShipmentRequest";
import Alert from "@material-ui/lab/Alert";
import EditRoute from "../EditRoute/EditRoute";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  subtitle: {
    variant: "h6",
    align: "center",
  },
  button: {
    width: 150,
    marginRight: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getAvailableOrders = (
  assignedOrders,
  orderData,
  neighborhoodsSelected
) => {
  var orderDataSelected = orderData;

  //Assigned Filter
  var assignedOrdersId = [];
  assignedOrders.map((order) => assignedOrdersId.push(order.id));
  orderDataSelected = orderDataSelected.filter(
    (order) => !assignedOrdersId.includes(order.id)
  );

  //Neighborhood Filter
  if (neighborhoodsSelected.length > 0) {
    orderDataSelected = orderDataSelected.filter((row) =>
      neighborhoodsSelected.includes(row.customer.zone.neighborhood)
    );
  }

  //State Filter === Pendiente
  orderDataSelected = orderDataSelected.filter(
    (row) => row.state === "PENDING"
  );

  return orderDataSelected;
};

const getNeighborhoodsFromZones = (zones) => {
  const neighborhoods = [];
  zones.forEach((zone) => {
    neighborhoods.push(zone.neighborhood);
  });

  return neighborhoods;
};

function AssignOrders(props) {
  const classes = useStyles();
  const { open, shipmentsData, setShipmentsData, setOpenAssignOrders, zones } =
    props;

  const [orderData, setOrderData] = useState([]);
  const [neighborhoodsSelected, setNeighborhoodsSelected] = useState([]);
  const [availableOrders, setAvailableOrders] = useState([]);
  const [availableOrdersSelected, setAvailableOrdersSelected] = useState([]);
  const [assignedOrders, setAssignedOrders] = useState([]);
  const [assignedOrdersSelected, setAssignedOrdersSelected] = useState([]);
  const [openEditRoute, setOpenEditRoute] = useState(false);

  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  var neighborhoodOptions = getNeighborhoodsFromZones(zones);

  useEffect(() => {
    if (open > -1) {
      var shipmentNeighborhoods = shipmentsData[open].zones.map((zone) => {
        return zone.neighborhood;
      });

      setAssignedOrders(shipmentsData[open].customerOrders);

      CustomerOrdersRequest.getUnassigned().then((response) => {
        setOrderData(response.data);

        setNeighborhoodsSelected(shipmentNeighborhoods);
      });
    } else {
      setOrderData([]);
      setNeighborhoodsSelected([]);
      setAvailableOrders([]);
      setAvailableOrdersSelected([]);
      setAssignedOrders([]);
      setAssignedOrdersSelected([]);
    }
  }, [open]);

  useEffect(() => {
    setAvailableOrders(
      getAvailableOrders(assignedOrders, orderData, neighborhoodsSelected)
    );
  }, [neighborhoodsSelected, assignedOrders]);

  const handleNeighborhoodChange = (event) => {
    setNeighborhoodsSelected(event.target.value);
  };

  const handleClearFilters = () => {
    setNeighborhoodsSelected([]);
  };

  const handleAssignOrders = () => {
    const updatedShipmentsData = JSON.parse(JSON.stringify(shipmentsData));
    updatedShipmentsData[open].customerOrders = assignedOrders.concat(
      availableOrdersSelected
    );

    setAssignedOrders(updatedShipmentsData[open].customerOrders);

    const updatedAvailableOrders = availableOrders.filter(
      (order) => !availableOrdersSelected.includes(order)
    );
    setAvailableOrders(updatedAvailableOrders);
    setAvailableOrdersSelected([]);
  };

  const handleRemoveAssignedOrders = () => {
    const updatedShipmentsData = JSON.parse(JSON.stringify(shipmentsData));
    updatedShipmentsData[open].customerOrders = assignedOrders.filter(
      (order) => {
        if (assignedOrdersSelected.includes(order)) {
          order.estimatedDeliveryDate = null;
          return false;
        } else {
          return true;
        }
      }
    );
    setAssignedOrders(updatedShipmentsData[open].customerOrders);

    const updatedOrderData = [...orderData];
    assignedOrdersSelected.forEach((reg) => {
      if (!updatedOrderData.includes(reg)) {
        updatedOrderData.push(reg);
      }
    });
    setOrderData(updatedOrderData);

    const updatedAvailableOrders = availableOrders
      .concat(assignedOrdersSelected)
      .sort(function (a, b) {
        return a.id - b.id;
      });
    setAvailableOrders(updatedAvailableOrders);
    setAssignedOrdersSelected([]);
  };

  const handleSaveChanges = (assignedOrders) => {
    const updatedShipmentsData = JSON.parse(JSON.stringify(shipmentsData));
    updatedShipmentsData[open].customerOrders = assignedOrders;
    ShipmentRequest.modifyShipmentData(updatedShipmentsData[open])
      .then((response) => {
        setShipmentsData(updatedShipmentsData);
        setsnackbarMessage("OK");
        setsnackbarSeverity("success");
        setsnackbarOpen(true);
      })
      .catch((error) => {
        setsnackbarMessage("" + error);
        setsnackbarSeverity("error");
        setsnackbarOpen(true);
      });
  };

  const handleOpenEditRoute = () => {
    setOpenEditRoute(true);
  };

  const handleCloseEditRoute = () => {
    setOpenEditRoute(false);
  };

  const handleCloseAssignOrders = () => {
    setOpenAssignOrders(-1);
  };

  const sortAssignedOrdersHandler = () => {
    const sortedOrders = assignedOrders.sort((a, b) =>
      a.customer.nameAndSurname.toUpperCase() >
      b.customer.nameAndSurname.toUpperCase()
        ? 1
        : -1
    );

    setAssignedOrders([...sortedOrders]);
  };

  var openThisDetail = false;
  if (open > -1) {
    openThisDetail = true;
  } else {
    openThisDetail = false;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={openThisDetail}
        onClose={handleCloseAssignOrders}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.AppBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseAssignOrders}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Asignar Pedidos
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container style={{ marginTop: 30, padding: 40 }} spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid
              container
              item
              xs={6}
              alignItems="flex-end"
              justify="space-around"
            >
              <Typography align="center">
                <b>Fecha:</b>
                <br />
                {open > -1 && printDate(shipmentsData[open].shippingDate)}
              </Typography>
              <Typography align="center">
                <b>Ciudades:</b>
                <br />
                {open > -1 && getShipmentCities(shipmentsData[open])}
              </Typography>
            </Grid>
            <Grid container item xs={6} alignItems="center" justify="flex-end">
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                endIcon={<SaveIcon />}
                onClick={() => handleSaveChanges(assignedOrders)}
              >
                Guardar
                <br />
                Cambios
              </Button>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity={snackbarSeverity}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
          <Divider />
          <Grid container item xs={12} spacing={2}>
            <Grid container item xs={6} alignItems="flex-end" justify="center">
              <SimpleFilter
                label="Barrios"
                name="barrio"
                value={neighborhoodsSelected}
                onChange={handleNeighborhoodChange}
                filterOptions={neighborhoodOptions}
                selectedOptions={neighborhoodsSelected}
              />
              <Button
                variant="text"
                color="primary"
                onClick={handleClearFilters}
              >
                Borrar Filtro
              </Button>
            </Grid>
            <Grid container item xs={6} alignItems="center" justify="flex-end">
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={sortAssignedOrdersHandler}
                disabled={assignedOrders.length === 0}
              >
                Ordenar
                <br />
                Alfabéticamente
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                endIcon={<AltRouteIcon />}
                onClick={handleOpenEditRoute}
                disabled={assignedOrders.length === 0}
              >
                Editar
                <br />
                Recorrido
              </Button>
              <Dialog
                fullScreen
                open={openEditRoute}
                onClose={handleCloseEditRoute}
                TransitionComponent={Transition}
              >
                <EditRoute
                  handleCloseEditRoute={handleCloseEditRoute}
                  assignedOrders={assignedOrders}
                  setAssignedOrders={setAssignedOrders}
                  handleSaveChanges={handleSaveChanges}
                  shipmentData={shipmentsData[open]}
                />
              </Dialog>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid
              container
              item
              xs={4}
              justify="flex-end"
              alignItems="flex-end"
            >
              <Typography variant="h6">
                Pedidos abiertos: {availableOrders.length}
              </Typography>
            </Grid>
            <Grid container item xs={2} justify="center">
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowRightIcon />}
                disabled={availableOrdersSelected.length === 0}
                onClick={handleAssignOrders}
              >
                Asignar
                <br />
                Pedidos
              </Button>
            </Grid>
            <Grid container item xs={2} justify="center">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<ArrowLeftIcon />}
                disabled={assignedOrdersSelected.length === 0}
                onClick={handleRemoveAssignedOrders}
              >
                Quitar
                <br />
                Pedidos
              </Button>
            </Grid>
            <Grid
              container
              item
              xs={4}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="h6">
                Pedidos asignados: {assignedOrders.length}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid container item xs={6}>
              <AssignOrdersTable
                orders={availableOrders}
                ordersSelected={availableOrdersSelected}
                setOrdersSelected={setAvailableOrdersSelected}
                checkColor="primary"
              />
            </Grid>
            <Grid container item xs={6}>
              <AssignOrdersTable
                orders={assignedOrders}
                ordersSelected={assignedOrdersSelected}
                setOrdersSelected={setAssignedOrdersSelected}
                checkColor="secondary"
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
export default AssignOrders;
