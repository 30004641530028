export const dateToDayMonthAndTime = (stringDate) => {
  if (stringDate == null) return "-";

  var date = new Date(stringDate);

  var dd = date.getDate();
  if (dd < 10) {
    dd = "0" + dd;
  }

  var mm = date.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }

  let hours = date.getHours();
  if (hours < 10) {
    hours = "0" + hours;
  }

  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  return dd + "/" + mm + " " + hours + ":" + minutes;
};

export const dateToDayMonthYear = (stringDate) => {
  if (stringDate == null) return "-";

  var date = new Date(stringDate);

  var dd = date.getDate();
  if (dd < 10) {
    dd = "0" + dd;
  }

  var mm = date.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }

  var yy = date.getFullYear();
  yy = yy.toString().substring(2, 4);

  return dd + "/" + mm + "/" + yy;
};

export const dateToDayMonth = (date) => {
  var dd = date.getDate();
  if (dd < 10) {
    dd = "0" + dd;
  }

  var mm = date.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }

  return dd + "/" + mm;
};

export const stringToDayMonth = (stringDate) => {
  if (stringDate == null) return "-";

  var date = new Date(stringDate);

  var dd = date.getDate();
  if (dd < 10) {
    dd = "0" + dd;
  }

  var mm = date.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }

  return dd + "/" + mm;
};
