import React, { useState, useEffect } from "react";
import { Grid, Button, Divider } from "@material-ui/core";
import RouteIcon from "@material-ui/icons/Directions";
import { makeStyles } from "@material-ui/core/styles";
import DateFilter from "../../../shared/Filters/DateFilter";
import SimpleFilter, {
  getSelectInnerOptions,
  getSelectSimpleOptions,
} from "../../../shared/Filters/SimpleFilter";
import StateFilter from "../../../shared/Filters/StateFilter";
import ShipmentsTable from "./ShipmentsTable";
import EditShipment from "./EditShipment";
import ShipmentRequest from "../../../services/ShipmentRequest";
import ZoneRequest from "../../../services/ZoneRequest";
import AssignOrders from "./AssignOrders/AssignOrders";
import ConfirmShipment from "./ConfirmShipment/ConfirmShipment";
import MapServices from "../../../services/MapServices";
import mapboxgl from "mapbox-gl";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialFromDate = new Date();
initialFromDate.setDate(initialFromDate.getDate() - 7);
initialFromDate.setHours(0, 0, 0, 0);

const initialToDate = new Date();
initialToDate.setDate(initialToDate.getDate() + 7);
initialToDate.setHours(23, 59, 59, 99);

function getDataSelected(
  data,
  startDate,
  endDate,
  citiesSelected,
  statesSelected
) {
  var dataSelected = data;

  //Start Date Filter
  dataSelected = dataSelected.filter(
    (row) => new Date(row.shippingDate) >= startDate
  );

  //End Date Filter
  let inclusiveEndDate = new Date(endDate);
  inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

  dataSelected = dataSelected.filter(
    (row) => new Date(row.shippingDate) <= inclusiveEndDate
  );

  //City Filter
  if (citiesSelected.length > 0) {
    dataSelected = dataSelected.filter((row) => {
      for (const iZone in row.zones) {
        if (citiesSelected.includes(row.zones[iZone].city)) {
          return true;
        }
      }
      return false;
    });
  }

  //State Filter
  if (statesSelected.length > 0) {
    dataSelected = dataSelected.filter((row) =>
      statesSelected.includes(row.state)
    );
  }

  return dataSelected;
}

var zones = [];

function Operation() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [openEditShipment, setOpenEditShipment] = useState(-2);
  const [openAssignOrders, setOpenAssignOrders] = useState(-1);
  const [openConfirmShipment, setOpenConfirmShipment] = useState();
  const [startDate, setStartDate] = useState(initialFromDate);
  const [endDate, setEndDate] = useState(initialToDate);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [citiesSelected, setCitiesSelected] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [statesSelected, setStatesSelected] = useState([]);

  var dataSelected = getDataSelected(
    data,
    startDate,
    endDate,
    citiesSelected,
    statesSelected
  );

  useEffect(() => {
    setStateOptions(["PENDING"]);
    setStatesSelected(["PENDING"]);

    ZoneRequest.getData().then((response) => {
      zones = response.data;
    });

    //se precarga el token, porque si se busca justa cuando se necesita, el delay provoca error.
    MapServices.getMapboxToken().then((response) => {
      mapboxgl.accessToken = response.data;
      // console.log(response.data);
    });
  }, []);

  useEffect(() => {
    fetchShipmentData();
  }, [startDate, endDate]);

  const fetchShipmentData = () => {
    const inclusiveEndDate = new Date(endDate);
    inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

    ShipmentRequest.getData(startDate, inclusiveEndDate).then((response) => {
      setData(response.data);

      setCitiesOptions(getSelectInnerOptions(response.data, "zones", "city"));
      setStateOptions(getSelectSimpleOptions(response.data, "state"));

      dataSelected = getDataSelected(
        response.data,
        startDate,
        endDate,
        citiesSelected,
        statesSelected
      );
    });
  };

  const handleOpenNewShipment = () => {
    setOpenEditShipment(-1);
  };

  const handleOpenEditShipment = (rowIndex) => {
    setOpenEditShipment(rowIndex);
  };

  const handleOpenAssignOrders = (rowIndex) => {
    setOpenAssignOrders(rowIndex);
  };

  const handleOpenConfirmShipment = (shipment) => {
    setOpenConfirmShipment(shipment);
  };

  const handleNeighborhoodChange = (event) => {
    setCitiesSelected(event.target.value);
  };

  const handleStateChange = (event) => {
    setStatesSelected(event.target.value);
  };

  const handleClearFilters = () => {
    setStartDate(initialFromDate);
    setEndDate(initialToDate);
    setCitiesSelected([]);
    setStatesSelected([]);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4} direction="column">
        <Grid container item xs={12} justify="flex-end" direction="row">
          <Button
            color="secondary"
            variant="contained"
            endIcon={<RouteIcon />}
            onClick={handleOpenNewShipment}
          >
            Nueva
            <br />
            Ruta
          </Button>
          <ConfirmShipment
            openShipment={openConfirmShipment}
            setOpenConfirmShipment={setOpenConfirmShipment}
            shipmentsData={dataSelected}
            setShipmentsData={setData}
            fetchShipmentData={fetchShipmentData}
          />
          <EditShipment
            data={dataSelected}
            setData={setData}
            openEditShipment={openEditShipment}
            setOpenEditShipment={setOpenEditShipment}
            zones={zones}
          />
          <AssignOrders
            open={openAssignOrders}
            setOpenAssignOrders={setOpenAssignOrders}
            shipmentsData={dataSelected}
            setShipmentsData={setData}
            zones={zones}
          />
        </Grid>
        <Divider />
        <Grid container item xs={12} justify="space-evenly" alignItems="center">
          <Grid
            container
            item
            xs={6}
            justify="space-around"
            alignItems="center"
          >
            <DateFilter
              label="Desde"
              value={startDate}
              handleChangeValue={setStartDate}
            />
            <DateFilter
              label="Hasta"
              value={endDate}
              handleChangeValue={setEndDate}
            />
          </Grid>
          <Grid
            container
            item
            xs={4}
            justify="space-around"
            alignItems="center"
          >
            <SimpleFilter
              label="Ciudades"
              name="zone.city"
              value={citiesSelected}
              onChange={handleNeighborhoodChange}
              filterOptions={citiesOptions}
              selectedOptions={citiesSelected}
            />
            <StateFilter
              label="Estado"
              name="state"
              value={statesSelected}
              onChange={handleStateChange}
              filterOptions={stateOptions}
              selectedOptions={statesSelected}
            />
          </Grid>
          <Grid container item xs={2} justify="center">
            <Button color="primary" onClick={handleClearFilters}>
              Borrar Filtros
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ShipmentsTable
            dataSelected={dataSelected}
            data={data}
            setData={setData}
            handleOpenEditShipment={handleOpenEditShipment}
            handleOpenAssignOrders={handleOpenAssignOrders}
            handleOpenConfirmShipment={handleOpenConfirmShipment}
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default Operation;
