import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles, FormGroup } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles(() => ({
  dateFilter: {
    width: 140,
  },

  formGroup: {
    alignItems: 'center'
  }
}));

export const getFirstDate = (data) => {
  if (data.length > 0) {
    var firstDate = new Date(data[0].shippingDate);
    for (let iRow = 0, l = data.length; iRow < l; iRow += 1) {
      var iDate = new Date(data[iRow].shippingDate);
      if (iDate < firstDate) {
        firstDate = iDate;
      }
    }
    return firstDate;
  } else return null;
};

export const getLastDate = (data) => {
  if (data.length > 0) {
    var lastDate = new Date(data[0].shippingDate);
    for (let iRow = 0, l = data.length; iRow < l; iRow += 1) {
      const iDate = new Date(data[iRow].shippingDate);
      if (iDate > lastDate) {
        lastDate = iDate;
      }
    }
    return lastDate;
  } else return null;
};

function DateFilter(props) {
  const classes = useStyles();
  const { label, value, handleChangeValue } = props;

  return (
    <FormGroup className={classes.formGroup} row>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.dateFilter}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          label={label}
          value={value}
          onChange={handleChangeValue}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </FormGroup>
  );
}
export default DateFilter;
