import React, { useState } from "react";
import {
  Button,
  makeStyles,
  Typography,
  TextField,
  Paper,
  Grid,
} from "@material-ui/core";
import AuthService from "../../../services/Auth";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(3),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const PasswordChangeComponent = (props) => {
  const { setsnackbarOpen, setsnackbarSeverity, setsnackbarMessage } = props;
  const classes = useStyles();

  const [data, setData] = useState({
    userId: AuthService.getCurrentUser().id,
    previousPassword: null,
    newPassword: null,
  });

  const handleInputChange = (event) => {
    const previousData = data;
    setData({ ...previousData, [event.target.id]: event.target.value });
  };

  const toogleButton = async (event) => {
    event.preventDefault();

    let response;
    try {
      response = await AuthService.changePassword(data);
      setsnackbarMessage("OK");
      setsnackbarSeverity("success");
      setsnackbarOpen(true);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          Cambio de contraseña
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              type="password"
              name="previousPassword"
              id="previousPassword"
              label="Contraseña previa"
              autoComplete="previous-password"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              type="password"
              name="newPassword"
              id="newPassword"
              label="Contraseña nueva"
              autoComplete="new-password"
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={toogleButton}
        >
          Guardar cambios
        </Button>
      </Paper>
    </main>
  );
};

export default PasswordChangeComponent;
