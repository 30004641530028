import React, { useEffect, useState } from "react";
import { makeStyles, Snackbar, Grid, Divider } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ProductRequest from "../../../services/ProductRequest";
import DateFilter from "../../../shared/Filters/DateFilter";
import ProductTable from "../../../shared/ProductTable";
import SalesChart from "./SalesChart";
import CustomerOrdersRequest from "../../../services/CustomerOrdersRequest";
import CustomerRequest from "../../../services/CustomerRequest";
import CustomersTable from "./CustomersTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: { margin: "30px" },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const getDefaultStartDate = () => {
  let date = new Date();
  date.setDate(date.getDate() - 31);
  date.setHours(0, 0, 0, 0);
  return date;
};

const Sales = () => {
  const classes = useStyles();

  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [salesRecords, setSalesRecords] = useState([]);

  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(new Date());

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  useEffect(() => {
    fetchProducts();
    fetchCustomers();
    fetchSalesRecords();
  }, []);

  useEffect(() => {
    fetchSalesRecords();
  }, [startDate, endDate]);

  const fetchProducts = async () => {
    let response;
    try {
      response = await ProductRequest.getData();
      setProducts(response.data);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const fetchCustomers = async () => {
    let response;
    try {
      response = await CustomerRequest.getAllCustomers();
      setCustomers(response.data);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const fetchSalesRecords = async () => {
    let inclusiveEndDate = new Date(endDate);
    inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

    let response;
    try {
      response = await CustomerOrdersRequest.getSalesRecords(
        startDate,
        inclusiveEndDate
      );
      setSalesRecords(response.data);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  return (
    <div>
      <Grid container className={classes.root} justify="space-between">
        <Grid
          container
          className={classes.root}
          spacing={2}
          direction="column"
          xs={12}
          sm={6}
        >
          <Grid
            container
            className={classes.root}
            justify="space-evenly"
            alignItems="flex-start"
          >
            <DateFilter
              label="Desde"
              value={startDate}
              handleChangeValue={setStartDate}
            />
            <DateFilter
              label="Hasta"
              value={endDate}
              handleChangeValue={setEndDate}
            />
          </Grid>

          <Divider variant="middle" flexItem className={classes.divider} />

          <Grid
            container
            className={classes.root}
            justify="space-evenly"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item sm={6}>
              <ProductTable
                products={products}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
              />
            </Grid>
            <Grid item sm={6}>
              <CustomersTable
                customers={customers}
                selectedCustomers={selectedCustomers}
                setSelectedCustomers={setSelectedCustomers}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.root}
          spacing={2}
          xs={6}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <SalesChart
              selectedProducts={selectedProducts}
              selectedCustomers={selectedCustomers}
              salesRecords={salesRecords}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Sales;
