import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL } from "../util/Constants";

const path = "depot";

class DepotServices {
  get() {
    return axios.get(API_URL + path, {
      headers: authHeader(),
    });
  }

  post(data) {
    return axios.post(API_URL + path, data, {
      headers: authHeader(),
    });
  }

  testEmail() {
    return axios.post(API_URL + path + "/testEmail", null, {
      headers: authHeader(),
    });
  }
}

export default new DepotServices();
