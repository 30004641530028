import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { dateToDayMonth } from "../../../../util/DateUtils";

const useStyles = makeStyles({
  table: {
    padding: "none",
  },

  tableContainer: {
    maxHeight: window.screen.height - 410,
    width: "100%",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

let days = [];
for (let i = 0; i < 7; i++) {
  let day = new Date();
  day.setDate(day.getDate() + i);
  days.push(day);
}

function StockForecastTable(props) {
  const {
    stockForecastList,
    requestedQuantities,
    handleRequestedQuantityChange,
  } = props;
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell key={"ProductKey"} align="left">
              Artículo
            </StyledTableCell>
            <StyledTableCell key={"AverageConsumption"} align="left">
              Consumo Semanal
            </StyledTableCell>
            <StyledTableCell key={"CurrentStockKey"} align="right">
              Stock Actual
            </StyledTableCell>
            {days.map((day, index) => (
              <StyledTableCell key={"DayKey" + index} align="right">
                {dateToDayMonth(day)}
              </StyledTableCell>
            ))}
            <StyledTableCell key={"RequestQuantityKey"} align="right">
              Cantidad a Solicitar
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stockForecastList.map((stockForecast, index) => (
            <TableRow key={index} hover>
              <StyledTableCell align="left">
                {stockForecast.productDescription}
              </StyledTableCell>
              <StyledTableCell align="right">
                {stockForecast.estimatedWeeklyDemand}
              </StyledTableCell>
              <StyledTableCell align="right">
                {stockForecast.currentStock}
              </StyledTableCell>
              {stockForecast.dailyForecast.map((dayForecast, subindex) => (
                <StyledTableCell key={index + "-" + subindex} align="right">
                  {dayForecast}
                </StyledTableCell>
              ))}
              <StyledTableCell align="right">
                <TextField
                  name="requestedQuantity"
                  value={requestedQuantities[index]}
                  size="small"
                  align="right"
                  type="number"
                  onChange={(event) =>
                    handleRequestedQuantityChange(
                      event,
                      stockForecast.productId
                    )
                  }
                  style={{ width: 60 }}
                  InputProps={{
                    style: { fontSize: 14 },
                    inputProps: {
                      min: 0,
                      style: { textAlign: "center" },
                    },
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StockForecastTable;
