import React, { useState } from "react";
import {
  Grid,
  TextField,
  Paper,
  IconButton,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Clear";
import { productList } from "./ClientOrders";
import { getProductSellPrice } from "../../../util/CustomerOrderUtils";

const useStyles = makeStyles(() => ({
  texField: {
    width: 130,
    color: "primary",
    size: "small",
    margin: "none",
  },

  table: {
    padding: "none",
  },
}));

function DetailRowEditor(props) {
  const { rowState, setRowState } = props;
  const [productSelected, setProductSelected] = useState(null);
  const [productName, setProductName] = useState("");
  const [quantity, setQuantity] = useState(0);

  const classes = useStyles();

  const handleProductSelection = (event) => {
    setProductName(event.target.value);
    setProductSelected(
      productList.find((product) => product.description === event.target.value)
    );
  };

  const handleSetQuantity = (event) => {
    setQuantity(event.target.value);
  };

  const handleAddToDetail = () => {
    const newDetail = {
      deliveredPrice: 0,
      deliveredQuantity: 0,
      quantity: quantity,
      product: productSelected,
    };
    setRowState((prevRowState) => {
      const customerOrderDetailsList = [
        ...prevRowState.customerOrderDetailsList,
      ];
      customerOrderDetailsList.push(newDetail);
      return { ...prevRowState, customerOrderDetailsList };
    });
    handleClearFields();
  };

  const handleClearFields = () => {
    setProductSelected(null);
    setProductName("");
    setQuantity(0);
  };

  const inputIsSet = () => {
    if (productSelected !== null) {
      return true;
    } else {
      return false;
    }
  };

  const getExcludedItems = (productList) => {
    const excludedItems = [];
    for (const product of productList) {
      var isIncluded = false;
      for (const detail of rowState.customerOrderDetailsList) {
        if (JSON.stringify(product) === JSON.stringify(detail.product)) {
          isIncluded = true;
        }
      }
      if (!isIncluded) {
        excludedItems.push(product);
      }
    }
    return excludedItems;
  };

  const TextFieldCommonProps = {
    className: classes.texField,
    InputProps: {
      style: { fontSize: 14 },
      inputProps: { min: 0, style: { textAlign: "center" } },
    },
    InputLabelProps: {
      style: { fontSize: 14 },
    },
  };

  return (
    <Grid container>
      <Grid item xs={10}>
        <TableContainer>
          <Table className={classes.table}>
            <TableBody>
              {inputIsSet() ? (
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <FormControl className={classes.formControl}>
                      <InputLabel>Articulo</InputLabel>
                      <Select
                        name="articulo"
                        value={productSelected.description}
                        onChange={handleProductSelection}
                        style={{ width: 130, fontSize: 14 }}
                      >
                        {productList.map((product) => (
                          <MenuItem
                            key={product.description}
                            value={product.description}
                          >
                            {product.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      {...TextFieldCommonProps}
                      name="cantidad"
                      label="Cantidad"
                      type="number"
                      value={quantity}
                      onChange={handleSetQuantity}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      {...TextFieldCommonProps}
                      name="unidad"
                      label="Unidad"
                      value={productSelected.unitOfMeasurement}
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      {...TextFieldCommonProps}
                      name="monto"
                      label="Monto(ARS)"
                      value={Math.round(
                        getProductSellPrice(productSelected, rowState) *
                          quantity
                      )}
                      disabled
                    />
                  </TableCell>
                  <TableCell />
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell align="right">
                    <FormControl className={classes.formControl}>
                      <InputLabel>Articulo</InputLabel>
                      <Select
                        name="productName"
                        value={productName}
                        onChange={handleProductSelection}
                        style={{ width: 130, fontSize: 14, textAlign: "left" }}
                      >
                        {getExcludedItems(productList).map((product) => (
                          <MenuItem
                            key={product.description}
                            value={product.description}
                          >
                            {product.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      {...TextFieldCommonProps}
                      name="quantity"
                      label="Cantidad"
                      type="number"
                      value={quantity}
                      onChange={handleSetQuantity}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        container
        item
        xs={2}
        justify="space-evenly"
        alignItems="center"
        component={Paper}
      >
        <IconButton
          size="small"
          onClick={handleAddToDetail}
          disabled={!inputIsSet()}
        >
          <CheckIcon />
        </IconButton>
        <IconButton size="small" onClick={handleClearFields}>
          <CancelIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
export default DetailRowEditor;
