import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Logo from "../../HuertaLogo.jpg";
import Routing from "./Routing";
import { withRouter } from "react-router-dom";
import AuthService from "../../services/Auth";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingIcon from "@material-ui/icons/ShoppingBasket";
import StoreIcon from "@material-ui/icons/Store";
import OperationIcon from "@material-ui/icons/LocalShipping";
import SalesIcon from "@material-ui/icons/MonetizationOn";
import StockIcon from "@material-ui/icons/ListAlt";
import ClientMasterIcon from "@material-ui/icons/FolderShared";
import LogOutIcon from "@material-ui/icons/MeetingRoom";
import ZoneMasterIcon from "@material-ui/icons/Explore";
import ProductMasterIcon from "@material-ui/icons/Eco";
import SupplierMasterIcon from "@material-ui/icons/People";
import AccountsMasterIcon from "@material-ui/icons/AccountBox";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

const drawerWidth = 240;

const appTitle = "Herramienta de Gestión";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  logoavatar: {
    marginLeft: 20,
    height: 50,
    width: 50,
  },
}));

const pathNameMap = new Map([
  ["main", "Dashboard"],
  ["dashboard", "Dashboard"],
  ["client-orders", "Pedidos de Clientes"],
  ["supplier-orders", "Pedidos a Proveedores"],
  ["operation", "Operación"],
  ["sales", "Ventas"],
  ["stock", "Stock"],
  ["client-master", "Maestro de Clientes"],
  ["product-master", "Maestro de Productos"],
  ["supplier-master", "Maestro de Proveedores"],
  ["zone-master", "Maestro de Zonas"],
  ["user-master", "Maestro de Usuarios"],
]);

const Main = (props) => {
  const { window, history } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sectionTitle, setsectionTitle] = useState(
    pathNameMap.get(
      history.location.pathname.substring(
        history.location.pathname.lastIndexOf("/") + 1
      )
    )
  );

  const handleNavClick = (path, title) => {
    setsectionTitle(title);
    history.push("/main/" + path);
    mobileOpen && handleDrawerToggle();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dashboardMenuList = [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      style: { fonstSize: "20px" },
      onClick: () => {
        handleNavClick("dashboard", "Dashboard");
      },
    },
  ];

  const operationsMenuList = [
    {
      buttonText: "Clientes",
      icon: <ShoppingIcon />,
      text: "Pedidos de Clientes",
      onClick: () => {
        handleNavClick("client-orders", "Pedidos de Clientes");
      },
    },
    {
      buttonText: "Proveedores",
      icon: <StoreIcon />,
      text: "Pedidos a Proveedores",
      onClick: () => {
        handleNavClick("supplier-orders", "Pedidos a Proveedores");
      },
    },
    {
      buttonText: "Operación",
      icon: <OperationIcon />,
      text: "Operación",
      onClick: () => {
        handleNavClick("operation", "Operación");
      },
    },
  ];

  const kpisMenuList = [
    {
      text: "Ventas",
      icon: <SalesIcon />,
      onClick: () => {
        handleNavClick("sales", "Ventas");
      },
    },
    {
      text: "Stock",
      icon: <StockIcon />,
      onClick: () => {
        handleNavClick("stock", "Stock");
      },
    },
    {
      text: "Cobros",
      icon: <LibraryBooksIcon />,
      onClick: () => {
        handleNavClick("collections-report", "Cobros");
      },
    },
  ];

  const mastersMenuList = [
    {
      buttonText: "ABM Clientes",
      icon: <ClientMasterIcon />,
      text: "Maestro de Clientes",
      onClick: () => {
        handleNavClick("client-master", "Maestro de Clientes");
      },
    },
    {
      buttonText: "ABM Productos",
      icon: <ProductMasterIcon />,
      text: "Maestro de Productos",
      onClick: () => {
        handleNavClick("product-master", "Maestro de Productos");
      },
    },
    {
      buttonText: "ABM Proveedores",
      icon: <SupplierMasterIcon />,
      text: "Maestro de Proveedores",
      onClick: () => {
        handleNavClick("supplier-master", "Maestro de Proveedores");
      },
    },
    {
      buttonText: "ABM Zonas",
      icon: <ZoneMasterIcon />,
      text: "Maestro de Zonas",
      onClick: () => {
        handleNavClick("zone-master", "Maestro de Zonas");
      },
    },
    {
      buttonText: "ABM Usuarios",
      icon: <AccountsMasterIcon />,
      text: "Maestro de Usuarios",
      onClick: () => {
        handleNavClick("user-master", "Maestro de Usuarios");
      },
    },
  ];

  const logoutMenuList = [
    {
      text: "Logout",
      icon: <LogOutIcon />,
      onClick: () => {
        AuthService.logout();
        history.push("/");
      },
    },
  ];

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {dashboardMenuList.map((item, index) => {
          const { text, icon, onClick } = item;
          return (
            <ListItem
              button
              key={text}
              selected={
                text ===
                pathNameMap.get(
                  history.location.pathname.substring(
                    history.location.pathname.lastIndexOf("/") + 1
                  )
                )
              }
              onClick={onClick}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <List>
        {operationsMenuList.map((item, index) => {
          const { text, icon, buttonText, onClick } = item;
          return (
            <ListItem
              button
              key={buttonText}
              selected={
                text ===
                pathNameMap.get(
                  history.location.pathname.substring(
                    history.location.pathname.lastIndexOf("/") + 1
                  )
                )
              }
              onClick={onClick}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={buttonText} />
            </ListItem>
          );
        })}
      </List>
      {AuthService.isAdmin() && (
        <div>
          <Divider />
          <List>
            {kpisMenuList.map((item, index) => {
              const { text, icon, onClick } = item;
              return (
                <ListItem
                  button
                  key={text}
                  selected={
                    text ===
                    pathNameMap.get(
                      history.location.pathname.substring(
                        history.location.pathname.lastIndexOf("/") + 1
                      )
                    )
                  }
                  onClick={onClick}
                >
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText primary={text} />
                </ListItem>
              );
            })}
          </List>
        </div>
      )}
      <Divider />
      <List>
        {mastersMenuList.map((item, index) => {
          const { text, icon, buttonText, onClick } = item;
          return (
            <ListItem
              button
              key={text}
              selected={
                text ===
                pathNameMap.get(
                  history.location.pathname.substring(
                    history.location.pathname.lastIndexOf("/") + 1
                  )
                )
              }
              onClick={onClick}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={buttonText} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <List>
        {logoutMenuList.map((item, index) => {
          const { text, icon, onClick } = item;
          return (
            <ListItem button key={text} onClick={onClick}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {sectionTitle}
          </Typography>
          <Hidden smDown implementation="css">
            <Typography variant="h6" noWrap>
              {appTitle}
            </Typography>
          </Hidden>
          <Avatar alt="Logo" src={Logo} className={classes.logoavatar} />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routing />
      </main>
    </div>
  );
};

Main.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withRouter(Main);
