import React, { useState, useEffect } from "react";
import MaterialTable, { MTableEditField, AutoComplete } from "material-table";
import TableIcons from "../../../shared/TableIcons";
import AuthService from "../../../services/Auth";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserTable = (props) => {
  const { setsnackbarOpen, setsnackbarSeverity, setsnackbarMessage } = props;
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchRoles();
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    let response;
    try {
      response = await AuthService.getUsers();

      const users = [];
      response.data.forEach((user) => {
        users.push({
          ...user,
          roles: user.roles.map((roles) => roles.name),
        });
      });

      setData(users);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const fetchRoles = async () => {
    let response;
    try {
      response = await AuthService.getRoles();
      setRoles(response.data);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const onUserAdd = async (newData) => {
    try {
      let response = await AuthService.register(
        newData.username,
        newData.email,
        newData.password,
        newData.roles
      );
      setData([...data, newData]);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const onUserModify = async (newData, oldData) => {
    try {
      let response = await AuthService.modifyUser(newData);

      const dataUpdate = [...data];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setData([...dataUpdate]);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const onUserDelete = async (oldData) => {
    try {
      let response = await AuthService.deleteUser(oldData.id);

      const dataDelete = [...data];
      const index = oldData.tableData.id;
      dataDelete.splice(index, 1);
      setData([...dataDelete]);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  return (
    <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
      <MaterialTable
        columns={[
          {
            title: "Username",
            field: "username",
            editable: "onAdd",
          },
          { title: "Email", field: "email" },
          {
            title: "Roles",
            field: "roles",
            editComponent: ({ value, onChange, rowData }) => (
              <FormControl className={classes.formControl}>
                <InputLabel id="mutiple-select-label">Roles</InputLabel>
                <Select
                  labelId="mutiple-select-label"
                  id="mutiple-select-name"
                  multiple
                  value={value === undefined ? [] : value}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {roles.map((roles) => (
                    <MenuItem key={roles.name} value={roles.name}>
                      {roles.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ),
          },
          { title: "Contraseña", field: "password", editable: "onAdd" },
        ]}
        data={data}
        title="Usuarios"
        icons={TableIcons}
        editable={{
          onRowAdd: onUserAdd,
          onRowUpdate: onUserModify,
          onRowDelete: onUserDelete,
        }}
      />
    </div>
  );
};

export default UserTable;
