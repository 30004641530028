import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  IconButton,
} from "@material-ui/core";
import { getProductSellPrice } from "../../../../util/CustomerOrderUtils";

const useStyles = makeStyles({
  table: {
    padding: "none",
  },

  tableContainer: {
    maxHeight: 400,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

function ShipmentOrderDetailTable(props) {
  const { rowState, setRowState, currentlyEditing } = props;
  const classes = useStyles();

  const handleQuantityEdit = (event, detailIndex) => {
    event.persist();
    setRowState((prevRowState) => {
      const customerOrderDetailsList = [
        ...prevRowState.customerOrderDetailsList,
      ];

      customerOrderDetailsList[detailIndex].deliveredQuantity =
        event.target.value;

      customerOrderDetailsList[detailIndex].deliveredPrice =
        event.target.value *
        getProductSellPrice(
          customerOrderDetailsList[detailIndex].product,
          rowState
        );

      return { ...prevRowState, customerOrderDetailsList };
    });
  };

  const handlePriceEdit = (event, detailIndex) => {
    event.persist();
    setRowState((prevRowState) => {
      const customerOrderDetailsList = [
        ...prevRowState.customerOrderDetailsList,
      ];

      customerOrderDetailsList[detailIndex].deliveredPrice = event.target.value;

      return { ...prevRowState, customerOrderDetailsList };
    });
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell key={"Articulo"} align="left">
              Articulo
            </StyledTableCell>
            <StyledTableCell key={"Cantidad"} align="right">
              Cantidad
            </StyledTableCell>
            <StyledTableCell key={"Unidad"} align="right">
              Unidad
            </StyledTableCell>
            <StyledTableCell key={"Monto"} align="right">
              Monto(ARS)
            </StyledTableCell>
            <StyledTableCell key={"Cantidad Despachada"} align="right">
              Cantidad Despachada
            </StyledTableCell>
            <StyledTableCell key={"Monto Despachado"} align="right">
              Monto Despachado
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowState.customerOrderDetailsList.map((orderDetail, index) => (
            <TableRow hover={currentlyEditing} key={orderDetail.id}>
              <StyledTableCell align="left">
                {orderDetail.product.description}
              </StyledTableCell>
              <StyledTableCell align="right">
                {orderDetail.quantity}
              </StyledTableCell>
              <StyledTableCell align="right">
                {orderDetail.product.unitOfMeasurement}
              </StyledTableCell>
              <StyledTableCell align="right">
                {"$" +
                  orderDetail.quantity *
                    Math.round(
                      getProductSellPrice(orderDetail.product, rowState)
                    )}
              </StyledTableCell>
              <StyledTableCell align="right">
                {currentlyEditing ? (
                  <TextField
                    name="deliveredQuantity"
                    value={orderDetail.deliveredQuantity}
                    size="small"
                    align="right"
                    type="number"
                    onChange={(event) => handleQuantityEdit(event, index)}
                    style={{ width: 60 }}
                    InputProps={{
                      style: { fontSize: 14 },
                      inputProps: {
                        min: 0,
                        style: { textAlign: "center" },
                      },
                    }}
                  />
                ) : (
                  orderDetail.deliveredQuantity
                )}
              </StyledTableCell>
              <StyledTableCell align="right">
                {currentlyEditing ? (
                  <TextField
                    name="deliveredPrice"
                    value={orderDetail.deliveredPrice}
                    size="small"
                    align="right"
                    type="number"
                    onChange={(event) => handlePriceEdit(event, index)}
                    style={{ width: 60 }}
                    InputProps={{
                      style: { fontSize: 14 },
                      inputProps: {
                        min: 0,
                        style: { textAlign: "center" },
                      },
                    }}
                  />
                ) : (
                  "$" + Math.round(orderDetail.deliveredPrice)
                )}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default ShipmentOrderDetailTable;
