import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { getProductSellPrice } from "../../../util/CustomerOrderUtils";

const useStyles = makeStyles({
  table: {
    padding: "none",
  },

  tableContainer: {
    maxHeight: 400,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

function DetailsTable(props) {
  const { rowState, setRowState, currentlyEditing } = props;
  const classes = useStyles();

  const handleEdit = (event, detailIndex) => {
    event.persist();
    setRowState((prevRowState) => {
      const customerOrderDetailsList = [
        ...prevRowState.customerOrderDetailsList,
      ];
      customerOrderDetailsList[detailIndex].quantity = parseInt(
        event.target.value
      );
      return { ...prevRowState, customerOrderDetailsList };
    });
  };

  const handleRemoveDetail = (removeIndex) => {
    setRowState((prevRowState) => {
      const customerOrderDetailsList = [
        ...prevRowState.customerOrderDetailsList,
      ];
      customerOrderDetailsList.splice(removeIndex, 1);
      return { ...prevRowState, customerOrderDetailsList };
    });
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell key={"Articulo"} align="left">
              Articulo
            </StyledTableCell>
            <StyledTableCell key={"Cantidad"} align="right">
              Cantidad
            </StyledTableCell>
            <StyledTableCell key={"Unidad"} align="right">
              Unidad
            </StyledTableCell>
            <StyledTableCell key={"Monto"} align="right">
              Monto(ARS)
            </StyledTableCell>
            <StyledTableCell key={"Cantidad Despachada"} align="right">
              Cantidad Despachada
            </StyledTableCell>
            <StyledTableCell key={"Monto Despachado"} align="right">
              Monto Despachado
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowState.customerOrderDetailsList.map((orderDetail, index) => (
            <TableRow hover={currentlyEditing} key={orderDetail.id}>
              <StyledTableCell align="center" padding="checkbox">
                {currentlyEditing && (
                  <IconButton
                    onClick={() => handleRemoveDetail(index)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </StyledTableCell>
              <StyledTableCell align="left">
                {orderDetail.product.description}
              </StyledTableCell>
              <StyledTableCell align="right">
                {currentlyEditing ? (
                  <TextField
                    name="Cantidad"
                    value={orderDetail.quantity}
                    size="small"
                    align="right"
                    type="number"
                    onChange={(event) => handleEdit(event, index)}
                    style={{ width: 60 }}
                    InputProps={{
                      style: { fontSize: 14 },
                      inputProps: { min: 0, style: { textAlign: "center" } },
                    }}
                  />
                ) : (
                  orderDetail.quantity
                )}
              </StyledTableCell>
              <StyledTableCell align="right">
                {orderDetail.product.unitOfMeasurement}
              </StyledTableCell>
              <StyledTableCell align="right">
                {"$" +
                  (
                    orderDetail.quantity *
                    getProductSellPrice(orderDetail.product, rowState)
                  ).toFixed(2)}
              </StyledTableCell>
              <StyledTableCell align="right">
                {orderDetail.deliveredQuantity}
              </StyledTableCell>
              <StyledTableCell align="right">
                {"$" + Math.round(orderDetail.deliveredPrice)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default DetailsTable;
