import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Divider,
  Typography,
  TextField,
  Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Create";
import PrintIcon from "@material-ui/icons/LocalPrintshop";
import { printDate } from "../../client-orders/OrdersTable";
import CustomerOrdersRequest from "../../../../services/CustomerOrdersRequest";
import ShipmentOrderDetailTable from "./ShipmentOrderDetailTable";
import { getProductSellPrice } from "./ShipmentOrderDetailTable";

export default function OrderDetails(props) {
  const {
    openShipment,
    setOpenShipment,
    openDetail,
    setOpenDetail,
    shipmentOrders,
    setShipmentOrders,
  } = props;

  const [currentlyEditing, setCurrentlyEditing] = useState(false);
  const [rowState, setRowState] = useState();

  useEffect(() => {
    if (openDetail > -1) {
      setRowState(JSON.parse(JSON.stringify(shipmentOrders[openDetail])));
    }
  }, [openDetail]);

  const handleSaveChanges = () => {
    const updatedRowState = rowState;

    CustomerOrdersRequest.modifyCustomerOrder(updatedRowState).then(
      (response) => {
        if (response.status === 200) {
          setCurrentlyEditing(false);

          const updatedShipment = openShipment;
          updatedShipment.customerOrders[openDetail] = rowState;

          setOpenShipment(updatedShipment);
          setShipmentOrders(updatedShipment.customerOrders);
        }
      }
    );
  };

  const handleCloseDetail = () => {
    setOpenDetail(-1);
    setRowState();
  };

  var openThisDetail = false;
  if (openDetail > -1) {
    openThisDetail = true;
  } else {
    openThisDetail = false;
  }

  const handlePrintCustomerOrder = (event) => {
    const customerOrderAsArray = [rowState];
    CustomerOrdersRequest.printTickets(customerOrderAsArray);
  };

  const handleDeliveryPriceChange = (event) => {
    event.persist();
    setRowState((prevRowState) => {
      const deliveryPrice = event.target.value;

      return { ...prevRowState, deliveryPrice };
    });
  };

  const handleCommentsForTheClientChange = (event) => {
    event.persist();
    setRowState((prevRowState) => {
      const commentsForTheClient = event.target.value;

      return { ...prevRowState, commentsForTheClient };
    });
  };

  return (
    <Dialog
      open={openThisDetail}
      onClose={() => {
        handleCloseDetail();
        setCurrentlyEditing(false);
      }}
      fullWidth
      maxWidth="md"
    >
      {rowState !== undefined && (
        <div style={{ padding: "0px 20px 20px" }}>
          <Grid container spacing={2} direction="column">
            <Grid container item xs={12} justify="center" spacing={1}>
              <DialogTitle>Detalle de pedido: {rowState.id}</DialogTitle>
            </Grid>
            {currentlyEditing ? (
              <Grid container item xs={6} justify="space-around" spacing={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  endIcon={<EditIcon />}
                  onClick={() => handleSaveChanges(rowState)}
                >
                  Guardar
                  <br />
                  Cambios
                </Button>
              </Grid>
            ) : (
              <Grid container item xs={6} justify="space-around" spacing={4}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<PrintIcon />}
                  onClick={handlePrintCustomerOrder}
                >
                  Imprimir
                  <br />
                  Pedido
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<EditIcon />}
                  onClick={() => setCurrentlyEditing(true)}
                  disabled={rowState.state != "PENDING"}
                >
                  Editar
                  <br />
                  Despacho
                </Button>
              </Grid>
            )}
            <Box m={1} />
            <Divider />
            <Grid item xs={12} container justify="space-around">
              <Typography align="center">
                <b>Fecha:</b>
                <br />
                {printDate(rowState.requestDate)}
              </Typography>
              <Typography align="center">
                <b>Cliente:</b>
                <br />
                {rowState.customer.nameAndSurname}
              </Typography>
              <Typography align="center">
                <b>Barrio:</b>
                <br />
                {rowState.customer.zone.neighborhood}
              </Typography>
              <Typography align="center">
                <b>Dirección:</b>
                <br />
                {rowState.customer.address}
              </Typography>
              <Typography align="center">
                <b>Dîa de Entrega:</b>
                <br />
                {rowState.shippingDate !== null
                  ? printDate(openShipment.shippingDate)
                  : "Fecha no calculada"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ShipmentOrderDetailTable
                rowState={rowState}
                setRowState={setRowState}
                currentlyEditing={currentlyEditing}
              />
            </Grid>

            <Typography align="center">
              <b>Precio de envío:</b>
              <br />
              {currentlyEditing ? (
                <TextField
                  name="deliveredPrice"
                  value={rowState.deliveryPrice}
                  size="small"
                  align="right"
                  type="number"
                  onChange={handleDeliveryPriceChange}
                  style={{ width: 60 }}
                  InputProps={{
                    style: { fontSize: 16 },
                    inputProps: {
                      min: 0,
                      style: { textAlign: "center" },
                    },
                  }}
                />
              ) : (
                "$" + rowState.deliveryPrice
              )}
            </Typography>
            {rowState.comments !== null && (
              <Grid container item xs={12}>
                <Box m={1} />
                <Grid item xs={12}>
                  <Typography>
                    Comentarios:
                    <br />
                    {rowState.comments}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12}>
              <Box m={1} />
              <Grid item xs={12}>
                <Typography>
                  Comentarios al cliente:
                  <br />
                  {currentlyEditing ? (
                    <TextField
                      name="commentsForTheClient"
                      value={rowState.commentsForTheClient}
                      size="large"
                      align="left"
                      type="text"
                      onChange={handleCommentsForTheClientChange}
                      style={{ width: 800 }}
                      InputProps={{
                        style: { fontSize: 16 },
                        inputProps: {
                          style: { textAlign: "left" },
                        },
                      }}
                    />
                  ) : (
                    rowState.commentsForTheClient
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </Dialog>
  );
}
