import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  Grid,
  Typography,
  Button,
  Slide,
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Divider,
  Paper,
  TextField,
} from "@material-ui/core";
import MapServices from "../../../../services/MapServices";
import DraggableOrderList from "./DraggableOrderList";
import CloseIcon from "@material-ui/icons/Close";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ConfirmRouteIcon from "@material-ui/icons/DoneOutline";
import CalculateIcon from "@material-ui/icons/Dialpad";
import DepotServices from "../../../../services/DepotServices";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  appBar: {
    position: "relative",
  },
  button: {
    height: 60,
    width: 130,
  },
}));

const styles = {
  width: "100%",
  height: window.screen.height - 380,
  position: "relative",
};

const EditRoute = (props) => {
  const {
    handleCloseEditRoute,
    assignedOrders,
    setAssignedOrders,
    handleSaveChanges,
    shipmentData,
  } = props;

  const classes = useStyles();
  const mapContainerRef = useRef(null);

  const [updatedAssignedOrders, setUpdatedAssignedOrders] = useState(
    assignedOrders
  );
  const [appMap, setAppMap] = useState(null);
  const [selectedDate, setSelectedDate] = useState(shipmentData.shippingDate);
  const [mapData, setMapData] = useState(null);
  const [homeLocation, setHomeLocation] = useState(null);
  const [routeData, setRouteData] = useState(null);
  const [waitTime, setWaitTime] = useState(0);
  const [totalWaitTIme, setTotalWaitTIme] = useState(0);

  const updateMapData = (assignedOrders) => {
    const featuresList = [];

    assignedOrders.map((order, index) => {
      if (order.deliveryNode == null) {
        featuresList.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [order.customer.longitude, order.customer.latitude],
          },
          properties: {
            id: order.id.toString(),
            name: order.customer.nameAndSurname,
          },
        });
      } else {
        featuresList.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              order.deliveryNode.longitude,
              order.deliveryNode.latitude,
            ],
          },
          properties: {
            id: order.id.toString(),
            name: order.customer.nameAndSurname,
          },
        });
      }
    });

    if (homeLocation === null) {
      DepotServices.get().then((response) => {
        const updatedHomeLocation = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [response.data.longitude, response.data.latitude],
          },
          properties: {
            name: "Depósito",
          },
        };
        featuresList.unshift(updatedHomeLocation);
        featuresList.push(updatedHomeLocation);

        setHomeLocation(updatedHomeLocation);
      });
    } else {
      featuresList.unshift(homeLocation);
      featuresList.push(homeLocation);
    }

    setMapData({
      type: "FeatureCollection",
      features: featuresList,
    });
  };

  useEffect(() => {
    updateMapData(updatedAssignedOrders);
  }, [updatedAssignedOrders]);

  useEffect(() => {
    var homeCoordinates = [-58.388163469387756, -34.70555364285715];
    if (homeLocation !== null) {
      homeCoordinates = homeLocation.geometry.coordinates;
    }
    const generatedMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: homeCoordinates,
      zoom: 10,
    });

    setAppMap(generatedMap);

    generatedMap.addControl(new mapboxgl.NavigationControl(), "top-right");

    generatedMap.on("load", () => {
      generatedMap.addSource("data-points", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: mapData.features.slice(1, mapData.features.length - 1),
        },
      });

      generatedMap.addLayer({
        id: "data-points-layer",
        source: "data-points",
        type: "circle",
        paint: {
          "circle-color": "#fc0303",
          "circle-radius": 9,
        },
      });

      generatedMap.addLayer(
        {
          id: "data-points-symbols",
          type: "symbol",
          source: "data-points",
          filter: ["!has", "singles_count"],
          layout: {
            "text-field": ["get", "name"],
            "text-size": 13,
            "text-padding": 0,
            "text-offset": [0, 1.5],
          },
          paint: {
            "text-color": "#fc0303",
          },
        },
        0
      );

      if (homeLocation !== null) {
        generatedMap.addSource("home-location", {
          type: "geojson",
          data: { type: "FeatureCollection", features: [mapData.features[0]] },
        });

        generatedMap.addLayer({
          id: "home-location-layer",
          source: "home-location",
          type: "circle",
          paint: {
            "circle-color": "#fc0303",
            "circle-radius": 7,
          },
        });
      }
    });

    return () => {
      generatedMap.remove();
    };
  }, [homeLocation]);

  const handleDateChange = (date) => {
    setRouteData(null);
    setSelectedDate(date);
  };

  const updateRoute = async (event) => {
    let response;

    try {
      response = await MapServices.getDirections(mapData);
    } catch (error) {
      console.log(error); //TODO aca se podria mostrar un snackbar o un UI feedback
      return;
    }

    setRouteData(response.data.routes[0]);
    let geojson = {
      type: "Feature",
      properties: {},
      geometry: response.data.routes[0].geometry,
    };

    if (appMap.getSource("routeData")) {
      appMap.getSource("routeData").setData(geojson);
    } else {
      appMap.addSource("routeData", {
        type: "geojson",
        data: geojson,
      });

      appMap.addLayer({
        id: "route",
        type: "line",
        source: "routeData",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#3887be",
          "line-width": 5,
          "line-opacity": 0.75,
        },
      });
    }
  };

  useEffect(() => {
    if (routeData !== null) {
      const ETAincluded = [...updatedAssignedOrders];

      let auxTotalWaitTime = 0;

      const departureDateInSeconds = new Date(selectedDate).getSeconds();
      let accumlatedTime = 0;

      ETAincluded.forEach((order, index) => {
        let legTime = routeData.legs[index].duration;

        if (
          index > 0 &&
          (order.deliveryNode == null ||
            ETAincluded[index - 1].deliveryNode == null ||
            ETAincluded[index - 1].deliveryNode.address !=
              order.deliveryNode.address)
        ) {
          const extraTimeInSeconds = waitTime * 60;
          legTime += extraTimeInSeconds;
          auxTotalWaitTime += extraTimeInSeconds;
        }

        setTotalWaitTIme(auxTotalWaitTime);

        accumlatedTime += legTime;

        order.estimatedDeliveryDate = new Date(selectedDate);
        order.estimatedDeliveryDate.setSeconds(
          departureDateInSeconds + accumlatedTime
        );
      });

      setUpdatedAssignedOrders(ETAincluded);
    }
  }, [routeData]);

  const handleConfirmRoute = () => {
    setAssignedOrders(updatedAssignedOrders);
    handleCloseEditRoute();
  };

  return (
    <div>
      <AppBar className={classes.AppBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseEditRoute}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Editar Recorrido
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid
        container
        direction="column"
        style={{ marginTop: 30, padding: 40 }}
        spacing={2}
      >
        <Grid container item direction="row">
          <Grid
            container
            item
            xs={4}
            justify="space-around"
            alignItems="center"
          >
            {routeData !== null && (
              <Typography align="center">
                <b>Distancia total:</b>
                <br />
                {(routeData.distance / 1000).toFixed(1)} Km
              </Typography>
            )}
            {routeData !== null && (
              <Typography align="center">
                <b>Tiempo Total:</b>
                <br />
                {((routeData.duration + totalWaitTIme) / 3600).toFixed(1)} Hs
              </Typography>
            )}
          </Grid>
          <Grid
            container
            item
            xs={8}
            spacing={2}
            justify="space-around"
            alignItems="center"
          >
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Hora de Salida"
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{ width: 150 }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              {" "}
              <TextField
                id="waitTime"
                name="waitTime"
                label="Espera (minutos)"
                defaultValue="0"
                onChange={(event) => setWaitTime(event.target.value)}
              />
            </Grid>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={updateRoute}
              endIcon={<CalculateIcon />}
              disabled={selectedDate === null}
            >
              Calcular
              <br />
              recorrido
            </Button>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={handleConfirmRoute}
              endIcon={<ConfirmRouteIcon />}
              disabled={routeData === null}
            >
              Confirmar
              <br />
              recorrido
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item spacing={2}>
          <Grid item xs={4} component={Paper}>
            <Typography variant="h5" align="center">
              Recorrido
            </Typography>
            <DraggableOrderList
              selectedDate={selectedDate}
              updatedAssignedOrders={updatedAssignedOrders}
              setUpdatedAssignedOrders={setUpdatedAssignedOrders}
              routeData={routeData}
              setRouteData={setRouteData}
              appMap={appMap}
            />
          </Grid>
          <Grid item xs>
            <div ref={mapContainerRef} style={styles} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditRoute;
