import React, { useState, useEffect } from "react";
import {
  Button,
  makeStyles,
  Snackbar,
  Typography,
  TextField,
  Paper,
  Grid,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import DepotServices from "../../../services/DepotServices";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const DepotComponent = () => {
  const classes = useStyles();
  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  const [depot, setDepot] = useState({
    address: null,
    latitude: null,
    longitude: null,
    companyTelephoneNumber: null,
    companyEmail: null,
    companyEmailPassword: null,
  });

  useEffect(() => {
    loadInitialDepot();
  }, []);

  const loadInitialDepot = async () => {
    let response;
    try {
      response = await DepotServices.get();
      setDepot(response.data);

      document.getElementById("address").value = response.data.address;
      document.getElementById("latitude").value = response.data.latitude;
      document.getElementById("longitude").value = response.data.longitude;
      document.getElementById("companyTelephoneNumber").value =
        response.data.companyTelephoneNumber;
      document.getElementById("companyEmail").value =
        response.data.companyEmail;

      setsnackbarMessage("OK");
      setsnackbarSeverity("success");
      setsnackbarOpen(true);
    } catch (error) {
      if (document.getElementById("address") == null) {
        return;
      }

      document.getElementById("address").value = "";
      document.getElementById("latitude").value = "";
      document.getElementById("longitude").value = "";
      document.getElementById("companyTelephoneNumber").value = "";
      document.getElementById("companyEmail").value = "";
      document.getElementById("companyEmailPassword").value = "";

      setsnackbarMessage("" + error);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const handleDepotInputChange = (event) => {
    const previousDepot = depot;
    setDepot({ ...previousDepot, [event.target.id]: event.target.value });
  };

  const saveDepotHandler = async (event) => {
    event.preventDefault();

    if (depot.address.length === 0) {
      alert("Complete fields");
      return;
    }

    let response;
    try {
      response = await DepotServices.post(depot);
      setsnackbarMessage("OK");
      setsnackbarSeverity("success");
      setsnackbarOpen(true);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const testEmailHandler = async (event) => {
    let response;
    try {
      response = await DepotServices.testEmail();
      setsnackbarMessage("OK");
      setsnackbarSeverity("success");
      setsnackbarOpen(true);
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  return (
    <div>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Depósito y datos de contacto
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                id="address"
                name="address"
                label="Dirección"
                fullWidth
                onChange={handleDepotInputChange}
                defaultValue=" "
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="latitude"
                name="latitude"
                label="Latitud"
                fullWidth
                onChange={handleDepotInputChange}
                defaultValue=" "
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="longitude"
                name="longitude"
                label="Longitud"
                fullWidth
                onChange={handleDepotInputChange}
                defaultValue=" "
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="companyTelephoneNumber"
                name="companyTelephoneNumber"
                label="Teléfono de contacto"
                fullWidth
                onChange={handleDepotInputChange}
                defaultValue=" "
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="companyEmail"
                name="companyEmail"
                label="Email de contacto"
                fullWidth
                onChange={handleDepotInputChange}
                defaultValue=" "
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                type="password"
                name="companyEmailPassword"
                id="companyEmailPassword"
                label="Email Password"
                autoComplete="current-password"
                onChange={handleDepotInputChange}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={saveDepotHandler}
          >
            Guardar cambios
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={testEmailHandler}
          >
            Enviar email de prueba
          </Button>
        </Paper>
      </main>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DepotComponent;
