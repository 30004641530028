import React, { useState } from "react";
import { makeStyles, Snackbar, Grid, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import DateFilter from "../../../shared/Filters/DateFilter";
import CustomerOrdersRequest from "../../../services/CustomerOrdersRequest";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: { margin: "30px" },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const getDefaultStartDate = () => {
  let date = new Date();
  date.setDate(date.getDate() - 14);
  date.setHours(0, 0, 0, 0);
  return date;
};

const CollectionsReport = () => {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(new Date());

  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  const fetchCollectionsReport = async () => {
    let inclusiveEndDate = new Date(endDate);
    inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

    let response;
    try {
      response = await CustomerOrdersRequest.getCollectionsReport(
        startDate,
        inclusiveEndDate
      );
    } catch (error) {
      setsnackbarMessage("" + error + " - " + error.response.data.message);
      setsnackbarSeverity("error");
      setsnackbarOpen(true);
    }
  };

  return (
    <div>
      <Grid container className={classes.root} justify="space-between">
        <Grid container className={classes.root} spacing={2} direction="column">
          <Grid
            container
            className={classes.root}
            justify="space-evenly"
            alignItems="flex-start"
          >
            <DateFilter
              label="Desde"
              value={startDate}
              handleChangeValue={setStartDate}
            />
            <DateFilter
              label="Hasta"
              value={endDate}
              handleChangeValue={setEndDate}
            />
            <Button
              variant="contained"
              color="primary"
              component="span"
              onClick={fetchCollectionsReport}
            >
              Generar
              <br />
              Reporte
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CollectionsReport;
