import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Divider,
  Typography,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Create";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import DetailsTable from "./DetailsTable";
import OpenIcon from "@material-ui/icons/Launch";
import DetailRowEditor from "./DetailRowEditor";
import { printDate } from "./OrdersTable";
import CustomerOrdersRequest from "../../../services/CustomerOrdersRequest";

export default function OrderDetails(props) {
  const { openDetail, setOpenDetail, data, setData } = props;
  const [currentlyEditing, setCurrentlyEditing] = useState(false);
  const [rowState, setRowState] = useState();
  const [orderState, setOrderState] = useState();
  const [openCancelOrder, setOpenCancelOrder] = useState(false);

  useEffect(() => {
    if (openDetail > -1) {
      setRowState(JSON.parse(JSON.stringify(data[openDetail])));
      setOrderState(data[openDetail].state);
    }
  }, [openDetail]);

  const handleConfirmCancelOrder = () => {
    const updatedRowSelected = JSON.parse(JSON.stringify(data[openDetail]));
    setRowState((prevRowState) => {
      const state = "CANCELLED";
      updatedRowSelected.state = state;
      return { ...prevRowState, state };
    });
    setOrderState("CANCELLED");
    setOpenCancelOrder(false);

    handleSaveChanges(updatedRowSelected);
  };

  const handleSetStatePending = () => {
    setRowState((prevRowState) => {
      const state = "PENDING";
      return { ...prevRowState, state };
    });
    setOrderState("PENDING");
  };

  const handleSetStateCompleted = () => {
    setRowState((prevRowState) => {
      const state = "COMPLETED";
      return { ...prevRowState, state };
    });
    setOrderState("COMPLETED");
  };

  const getState = () => {
    if (rowState.state === "PENDING") {
      return "Pendiente";
    } else if (rowState.state === "COMPLETED") {
      return "Completado";
    } else {
      return "Cancelado";
    }
  };

  const handleSaveChanges = (row) => {
    CustomerOrdersRequest.modifyCustomerOrder(row).then((response) => {
      if (response.status === 200) {
        const updatedRowSelected = response.data;
        const updatedData = [...data];
        const dataIndex = data.indexOf(data[openDetail]);
        updatedData[dataIndex] = updatedRowSelected;

        setData(updatedData);
        setCurrentlyEditing(false);
      }
    });
  };

  const handleCloseDetail = () => {
    setOpenDetail(-1);
    setRowState();
  };

  var openThisDetail = false;
  if (openDetail > -1) {
    openThisDetail = true;
  } else {
    openThisDetail = false;
  }

  return (
    <Dialog
      open={openThisDetail}
      onClose={() => {
        handleCloseDetail();
        setRowState(JSON.parse(JSON.stringify(data[openDetail])));
        setCurrentlyEditing(false);
        setOrderState(data[openDetail].state);
      }}
      fullWidth
      maxWidth="md"
    >
      <div style={{ padding: "0px 20px 20px" }}>
        <Grid container spacing={2} direction="column">
          <Grid container item xs={12} justify="center" spacing={1}>
            <DialogTitle>
              Detalle de pedido: {openThisDetail && data[openDetail].id}
            </DialogTitle>
          </Grid>
          {currentlyEditing ? (
            <Grid container item xs={12} justify="space-around" spacing={4}>
              <Button
                variant="outlined"
                color="secondary"
                endIcon={<EditIcon />}
                onClick={() => handleSaveChanges(rowState)}
              >
                Guardar
                <br />
                Cambios
              </Button>
              {orderState === "PENDING" ? (
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<CloseIcon />}
                  onClick={handleSetStateCompleted}
                >
                  Cerrar
                  <br />
                  pedido
                </Button>
              ) : (
                orderState === "COMPLETED" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<OpenIcon />}
                    onClick={handleSetStatePending}
                  >
                    Abrir
                    <br />
                    pedido
                  </Button>
                )
              )}
              <Button
                variant="contained"
                color="secondary"
                endIcon={<DeleteSweepIcon />}
                style={{
                  borderRadius: 35,
                  backgroundColor: "#8B0000",
                }}
                onClick={() => setOpenCancelOrder(true)}
              >
                Cancelar
                <br />
                Pedido
              </Button>
              <Dialog
                open={openCancelOrder}
                onClose={() => setOpenCancelOrder(false)}
              >
                <div style={{ padding: 20 }}>
                  <DialogTitle>{`Cancelar pedido ${data[openDetail].id}? `}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Una vez cancelado no se podrá recuperar el pedido
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={handleConfirmCancelOrder}>
                      Cancelar Pedido
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setOpenCancelOrder(false)}
                    >
                      Deshacer Acción
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>
            </Grid>
          ) : (
            <Grid container item xs={6} justify="space-around" spacing={4}>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<EditIcon />}
                onClick={() => setCurrentlyEditing(true)}
                disabled={orderState !== "PENDING"}
              >
                Editar
                <br />
                Pedido
              </Button>
            </Grid>
          )}
          <Box m={1} />
          <Divider />
          {rowState !== undefined && (
            <Grid item xs={12} container justify="space-around">
              <Typography align="center">
                <b>Fecha:</b>
                <br />
                {printDate(rowState.requestDate)}
              </Typography>
              <Typography align="center">
                <b>Cliente:</b>
                <br />
                {rowState.customer.nameAndSurname}
              </Typography>
              <Typography align="center">
                <b>Estado:</b>
                <br />
                {openThisDetail && getState(rowState)}
              </Typography>
              <Typography align="center">
                <b>Barrio:</b>
                <br />
                {rowState.customer.zone.neighborhood}
              </Typography>
              <Typography align="center">
                <b>Dirección:</b>
                <br />
                {rowState.customer.address}
              </Typography>
              <Typography align="center">
                <b>Día de Entrega:</b>
                <br />
                {rowState.estimatedDeliveryDay}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <DetailsTable
              rowState={rowState}
              setRowState={setRowState}
              data={data}
              currentlyEditing={currentlyEditing}
            />
          </Grid>
          {rowState !== undefined && currentlyEditing && (
            <Grid item xs={12}>
              <DetailRowEditor rowState={rowState} setRowState={setRowState} />
            </Grid>
          )}
          {rowState !== undefined && (
            <Typography align="center">
              <b>Precio de envío:</b>
              <br />
              {"$" + rowState.deliveryPrice}
            </Typography>
          )}
          {rowState !== undefined && rowState.comments !== null && (
            <Grid container item xs={12}>
              <Box m={1} />
              <Grid item xs={12}>
                <Typography>
                  Comentarios:
                  <br />
                  {rowState.comments}
                </Typography>
              </Grid>
            </Grid>
          )}
          {rowState !== undefined && rowState.commentsForTheClient !== null && (
            <Grid container item xs={12}>
              <Box m={1} />
              <Grid item xs={12}>
                <Typography>
                  Comentarios al cliente:
                  <br />
                  {rowState.commentsForTheClient}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </Dialog>
  );
}
