import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL } from "../util/Constants";

const path = "product";

class ProductRequest {
  getData() {
    return axios.get(API_URL + path, {
      headers: authHeader(),
    });
  }

  getStockForecast() {
    return axios.get(API_URL + path + "/stock-forecast", {
      headers: authHeader(),
    });
  }

  getStockRecords(fromDate, toDate) {
    return axios.get(API_URL + path + "/stock-records", {
      headers: authHeader(),
      params: { fromDate: fromDate, toDate: toDate },
    });
  }
}

export default new ProductRequest();
