import React, { useState } from "react";
import { Button, makeStyles, Divider, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { EXCEL_FILE_TYPE } from "../../../util/Constants";
import UploadIcon from "@material-ui/icons/Publish";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileExchange from "../../../services/FileExchange";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  divider: {
    height: 10,
  },
  divider2: {
    height: 20,
  },
}));

const ClientMaster = () => {
  const classes = useStyles();
  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  const uploadHandler = (event) => {
    let path = "";
    if (event.currentTarget.id === "uploadClientTypeMasterButton") {
      path = "customer_type/upload";
    } else if (event.currentTarget.id === "uploadClientMasterButton") {
      path = "customer/upload";
    } else {
      path = "payment_method/upload";
    }

    const data = new FormData();
    data.append("file", event.target.files[0]);

    event.target.value = null; //asi permite subir archivos consecutivamente

    FileExchange.upload(data, path)
      .then((response) => {
        setsnackbarMessage("OK");
        setsnackbarSeverity("success");
        setsnackbarOpen(true);
      })
      .catch((error) => {
        setsnackbarMessage("" + error + " - " + error.response.data.message);
        setsnackbarSeverity("error");
        setsnackbarOpen(true);
      });
  };

  const downloadHandler = (event) => {
    let path = "";
    if (event.currentTarget.id === "downloadClientTypeMasterButton") {
      path = "customer_type/download";
    } else if (event.currentTarget.id === "downloadClientMasterButton") {
      path = "customer/download";
    } else {
      path = "payment_method/download";
    }

    FileExchange.download(path)
      .then((response) => {
        setsnackbarMessage("OK");
        setsnackbarSeverity("success");
        setsnackbarOpen(true);
      })
      .catch((error) => {
        setsnackbarMessage("" + error);
        setsnackbarSeverity("error");
        setsnackbarOpen(true);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  return (
    <div className={classes.paper}>
      <div>
        <input
          accept={[EXCEL_FILE_TYPE]}
          className={classes.input}
          id="uploadClientTypeMasterButton"
          multiple
          type="file"
          onChange={uploadHandler}
        />
        <label htmlFor="uploadClientTypeMasterButton">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            endIcon={<UploadIcon />}
          >
            Subir Maestro de Tipo de Cliente
          </Button>
        </label>
      </div>
      <Divider className={classes.divider} />
      <div>
        <Button
          id="downloadClientTypeMasterButton"
          variant="contained"
          color="primary"
          component="span"
          onClick={downloadHandler}
          endIcon={<DownloadIcon />}
        >
          Descargar Maestro de Tipo de Cliente
        </Button>
      </div>
      <Divider className={classes.divider2} />
      <div>
        <input
          accept={[EXCEL_FILE_TYPE]}
          className={classes.input}
          id="uploadClientMasterButton"
          multiple
          type="file"
          onChange={uploadHandler}
        />
        <label htmlFor="uploadClientMasterButton">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            endIcon={<UploadIcon />}
          >
            Subir Maestro de Clientes
          </Button>
        </label>
      </div>
      <Divider className={classes.divider} />
      <div>
        <Button
          id="downloadClientMasterButton"
          variant="contained"
          color="primary"
          component="span"
          onClick={downloadHandler}
          endIcon={<DownloadIcon />}
        >
          Descargar Maestro de Clientes
        </Button>
      </div>
      <Divider className={classes.divider2} />
      <div>
        <input
          accept={[EXCEL_FILE_TYPE]}
          className={classes.input}
          id="uploadPaymentMethodMasterButton"
          multiple
          type="file"
          onChange={uploadHandler}
        />
        <label htmlFor="uploadPaymentMethodMasterButton">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            endIcon={<UploadIcon />}
          >
            Subir Maestro de Medios de Pago
          </Button>
        </label>
      </div>
      <Divider className={classes.divider} />
      <div>
        <Button
          id="downloadPaymentMethodMasterButton"
          variant="contained"
          color="primary"
          component="span"
          onClick={downloadHandler}
          endIcon={<DownloadIcon />}
        >
          Descargar Maestro de Medios de Pago
        </Button>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ClientMaster;
