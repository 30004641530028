import React, { useState, useEffect } from "react";
import { Grid, Divider, Button, Snackbar, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SimpleFilter from "../../../shared/Filters/SimpleFilter";
import DateFilter from "../../../shared/Filters/DateFilter";
import Alert from "@material-ui/lab/Alert";
import StateFilter from "../../../shared/Filters/StateFilter";
import ProductRequest from "../../../services/ProductRequest";
import SupplierOrdersTable from "./SupplierOrdersTable";
import SupplierOrderRequest from "../../../services/SupplierOrderRequest";
import SupplierOrderCreation from "./supplier-order-creation/SupplierOrderCreation";
import SupplierOrderEdit from "./supplier-order-edit/SupplierOrderEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  button: {
    marginLeft: theme.spacing(1),
  },
}));

function getSelectedOrders(
  orders,
  startDate,
  endDate,
  selectedStates,
  selectedProducts,
  selectedSuppliers
) {
  let selectedOrders = orders;

  //Start Date Filter
  selectedOrders = selectedOrders.filter(
    (row) => new Date(row.requestDate) >= startDate
  );

  //End Date Filter
  let inclusiveEndDate = new Date(endDate);
  inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

  selectedOrders = selectedOrders.filter(
    (row) => new Date(row.requestDate) <= inclusiveEndDate
  );

  //State Filter
  if (selectedStates.length > 0) {
    selectedOrders = selectedOrders.filter((row) =>
      selectedStates.includes(row.state)
    );
  }

  //Supplier Filter
  if (selectedSuppliers.length > 0) {
    selectedOrders = selectedOrders.filter((row) =>
      selectedSuppliers.includes(row.supplier.name)
    );
  }

  //Product Filter
  if (selectedProducts.length > 0) {
    selectedOrders = selectedOrders.filter((order) => {
      for (const detail of order.supplierOrderDetails) {
        if (selectedProducts.includes(detail.product.description)) {
          return true;
        }
      }
      return false;
    });
  }

  return selectedOrders;
}

export const initialFromDate = new Date();
initialFromDate.setDate(initialFromDate.getDate() - 7);
initialFromDate.setHours(0, 0, 0, 0);

export const initialToDate = new Date();
initialToDate.setDate(initialToDate.getDate() + 7);
initialToDate.setHours(23, 59, 59, 99);

function SupplierOrders() {
  const classes = useStyles();

  const [orders, setOrders] = useState([]);
  const [startDate, setStartDate] = useState(initialFromDate);
  const [endDate, setEndDate] = useState(new Date());
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [openCreateOrders, setOpenCreateOrders] = useState(false);
  const [openEditOrders, setOpenEditOrders] = useState(-1);

  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  useEffect(() => {
    fetchProducts();
    fetchStates();
    fetchSuppliers();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await ProductRequest.getData();
      let productsDescription = response.data.map(
        (product) => product.description
      );

      setProductsOptions(productsDescription);
    } catch (error) {
      showSnackbarError(error);
    }
  };

  const fetchStates = async () => {
    try {
      const response = await SupplierOrderRequest.getStates();
      setStateOptions(response.data);
      setSelectedStates(["PENDING"]);
    } catch (error) {
      showSnackbarError(error);
    }
  };

  const fetchSuppliers = async () => {
    try {
      const response = await SupplierOrderRequest.getSuppliers();
      let suppliersNames = response.data.map((supplier) => supplier.name);

      setSupplierOptions(suppliersNames);
    } catch (error) {
      showSnackbarError(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [startDate, endDate]);

  const fetchOrders = async () => {
    const inclusiveEndDate = new Date(endDate);
    inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

    try {
      const response = await SupplierOrderRequest.get(
        startDate,
        inclusiveEndDate
      );
      setOrders(response.data);
    } catch (error) {
      showSnackbarError(error);
    }
  };

  const handleStateChange = (event) => {
    setSelectedStates(event.target.value);
  };

  const handleProductChange = (event) => {
    setSelectedProducts(event.target.value);
  };

  const handleSupplierChange = (event) => {
    setSelectedSuppliers(event.target.value);
  };

  const handleClearFilters = () => {
    setSelectedStates([]);
    setSelectedProducts([]);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  const handleOpenCreateOrders = () => {
    setOpenCreateOrders(true);
  };

  const showSnackbarSuccess = () => {
    setsnackbarMessage("OK");
    setsnackbarSeverity("success");
    setsnackbarOpen(true);
  };

  const showSnackbarError = (error) => {
    setsnackbarMessage("" + error + " - " + error.response.data.message);
    setsnackbarSeverity("error");
    setsnackbarOpen(true);
  };

  return (
    <div className={classes.root}>
      {openCreateOrders && (
        <SupplierOrderCreation
          openCreateOrders={openCreateOrders}
          setOpenCreateOrders={setOpenCreateOrders}
          fetchOrders={fetchOrders}
          showSnackbarSuccess={showSnackbarSuccess}
          showSnackbarError={showSnackbarError}
        />
      )}
      {openEditOrders >= 0 && (
        <SupplierOrderEdit
          openEditOrders={openEditOrders}
          setOpenEditOrders={setOpenEditOrders}
          orderToEdit={orders.find((reg) => reg.id === openEditOrders)}
          showSnackbarSuccess={showSnackbarSuccess}
          showSnackbarError={showSnackbarError}
          fetchOrders={fetchOrders}
        />
      )}
      <Grid container spacing={4} direction="column">
        <Grid container item justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={handleOpenCreateOrders}
          >
            Nuevos Pedidos
          </Button>
        </Grid>
        <Divider />
        <Grid container item xs={12} justify="space-evenly" alignItems="center">
          <DateFilter
            label="Desde"
            value={startDate}
            handleChangeValue={setStartDate}
          />
          <DateFilter
            label="Hasta"
            value={endDate}
            handleChangeValue={setEndDate}
          />
          <SimpleFilter
            label="Proveedor"
            value={selectedSuppliers}
            onChange={handleSupplierChange}
            filterOptions={supplierOptions}
            selectedOptions={selectedSuppliers}
          />
          <SimpleFilter
            label="Artículo"
            value={selectedProducts}
            onChange={handleProductChange}
            filterOptions={productsOptions}
            selectedOptions={selectedProducts}
          />
          <StateFilter
            label="Estado"
            name="state"
            value={selectedStates}
            onChange={handleStateChange}
            filterOptions={stateOptions}
            selectedOptions={selectedStates}
          />
          <Button
            id="ClearFilters"
            variant="text"
            color="primary"
            onClick={handleClearFilters}
          >
            Borrar Filtros
          </Button>
        </Grid>
        <Grid item xs={12}>
          {
            <SupplierOrdersTable
              selectedOrders={getSelectedOrders(
                orders,
                startDate,
                endDate,
                selectedStates,
                selectedProducts,
                selectedSuppliers
              )}
              setOpenEditOrders={setOpenEditOrders}
            />
          }
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SupplierOrders;
