import React, { useState, useEffect } from "react";
import {
  Grid,
  Divider,
  Button,
  Snackbar,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import SimpleFilter from "../../../../shared/Filters/SimpleFilter";
import Alert from "@material-ui/lab/Alert";
import { dateToDayMonthYear } from "../../../../util/DateUtils";
import SupplierOrderEditTable from "./SupplierOrderEditTable";
import SupplierOrderRequest from "../../../../services/SupplierOrderRequest";
import stateToString from "../../../../util/StateUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },

  input: {
    display: "none",
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

function SupplierOrderEdit(props) {
  const {
    openEditOrders,
    setOpenEditOrders,
    orderToEdit,
    showSnackbarSuccess,
    showSnackbarError,
    fetchOrders,
  } = props;
  const classes = useStyles();

  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);

  const [productCategoryOptions, setProductCategoryOptions] = useState([]);
  const [selectedProductCategories, setSelectedProductCategories] = useState(
    []
  );

  useEffect(() => {
    setSelectedOrderDetails(orderToEdit.supplierOrderDetails);

    orderToEdit.supplierOrderDetails.forEach((orderDetails) => {
      let auxProductCategoryOptions = [];

      if (!auxProductCategoryOptions.includes(orderDetails.product.category)) {
        auxProductCategoryOptions.push(orderDetails.product.category);
      }

      auxProductCategoryOptions.sort();
      setProductCategoryOptions(auxProductCategoryOptions);
    });
  }, [orderToEdit]);

  useEffect(() => {
    let orderDetails = orderToEdit.supplierOrderDetails.slice();
    if (selectedProductCategories.length > 0) {
      orderDetails = orderDetails.filter((reg) =>
        selectedProductCategories.includes(reg.product.category)
      );
    }

    setSelectedOrderDetails(orderDetails);
  }, [selectedProductCategories]);

  const handleProductCategoryChange = (event) => {
    setSelectedProductCategories(event.target.value);
  };

  const handleCloseComponent = () => {
    fetchOrders();
    setOpenEditOrders(-1);
  };

  const handleRegisterStockEntryButton = async () => {
    let requestData = [];

    orderToEdit.supplierOrderDetails
      .filter(
        (orderDetail) =>
          orderDetail.stockEntry !== undefined && orderDetail.stockEntry > 0
      )
      .forEach((orderDetail) => {
        requestData.push({
          productId: orderDetail.product.id,
          quantity: Number(orderDetail.stockEntry),
        });
      });

    try {
      const response = await SupplierOrderRequest.registerStockEntry(
        orderToEdit.id,
        requestData
      );
      showSnackbarSuccess();
      fetchOrders();
    } catch (error) {
      showSnackbarError(error);
    }
  };

  const handleCompleteOrderButton = async () => {
    try {
      const response = await SupplierOrderRequest.completeOrder(orderToEdit.id);
      showSnackbarSuccess();
      handleCloseComponent();
    } catch (error) {
      showSnackbarError(error);
    }
  };

  return (
    <Dialog
      fullScreen
      open={openEditOrders >= 0}
      onClose={handleCloseComponent}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.AppBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseComponent}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Ingreso de Stock
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container style={{ marginTop: 30, padding: 40 }} spacing={2}>
        <Grid container>
          <Grid container direction="row" item xs={9} justify="space-evenly">
            <Typography align="center">
              <b>ID Pedido</b>
              <br />
              {orderToEdit.id}
            </Typography>
            <Typography align="center">
              <b>Proveedor</b>
              <br />
              {orderToEdit.supplier.name}
            </Typography>
            <Typography align="center">
              <b>Fecha de Pedido</b>
              <br />
              {dateToDayMonthYear(orderToEdit.requestDate)}
            </Typography>
            <Typography align="center">
              <b>Estado</b>
              <br />
              {stateToString(orderToEdit.state)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              onClick={handleRegisterStockEntryButton}
              disabled={orderToEdit.state === "COMPLETED"}
            >
              Registrar ingreso
            </Button>
            <Button
              variant="contained"
              color="secondary"
              component="span"
              className={classes.button}
              onClick={handleCompleteOrderButton}
              disabled={orderToEdit.state === "COMPLETED"}
            >
              Cerrar pedido
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item xs={9} justify="flex-start" alignItems="center">
          <SimpleFilter
            label="Categoría"
            value={selectedProductCategories}
            onChange={handleProductCategoryChange}
            filterOptions={productCategoryOptions}
            selectedOptions={selectedProductCategories}
          />
        </Grid>
        <Grid item xs={12}>
          {
            <SupplierOrderEditTable
              orderToEdit={orderToEdit}
              orderDetailsList={selectedOrderDetails}
              setOrderDetailsList={setSelectedOrderDetails}
            />
          }
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default SupplierOrderEdit;
