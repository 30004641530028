import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import { ProtectedRoute } from "./shared/ProtectedRoute";
import AuthService from "./services/Auth";

import Login from "./views/Login";
import Main from "./views/logged/Main";

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path={["/", "/login"]} component={Login} />
        <ProtectedRoute path={"/main"} component={Main} />
        <Route path="*" component={() => "404 NOT FOUND"} />
      </Switch>
    </>
  );
};

export default App;
