import React from "react";
import { Typography, Avatar, Container, makeStyles } from "@material-ui/core";
import Logo from "../../../HuertaLogo.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logoavatar: {
    height: 150,
    width: 150,
    margin: 20,
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <Avatar alt="Logo" src={Logo} className={classes.logoavatar} />
      <Typography align="center">
        Bienvenido a la plataforma web de gestión Huerta Consciente Burzaco
      </Typography>
    </Container>
  );
};

export default Dashboard;
