import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL, PDF_FILE_TYPE, EXCEL_FILE_TYPE } from "../util/Constants";
import { saveAs } from "file-saver";

const path = "customerOrder";

class CustomerOrderRequest {
  upload(data) {
    return axios.post(API_URL + path, data, {
      headers: authHeader(),
    });
  }

  getData(fromDate, toDate) {
    return axios.get(API_URL + path, {
      headers: authHeader(),
      params: { fromDate: fromDate, toDate: toDate },
    });
  }

  getUnassigned() {
    return axios.get(API_URL + path + "/unassigned", {
      headers: authHeader(),
    });
  }

  modifyCustomerOrder(customerOrder) {
    return axios.post(API_URL + path, customerOrder, {
      headers: authHeader(),
    });
  }

  getSalesRecords(fromDate, toDate) {
    return axios.get(API_URL + path + "/sales-records", {
      headers: authHeader(),
      params: { fromDate: fromDate, toDate: toDate },
    });
  }

  printPDF(customerOrderList) {
    return axios
      .post(API_URL + path + "/pdf", customerOrderList, {
        headers: authHeader(),
        responseType: "arraybuffer",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"]
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();

        const blob = new Blob([response.data], {
          type: PDF_FILE_TYPE,
        });

        saveAs(blob, filename);

        return response;
      });
  }

  printTickets(customerOrderList) {
    return axios
      .post(API_URL + path + "/tickets", customerOrderList, {
        headers: authHeader(),
        responseType: "arraybuffer",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"]
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();

        const blob = new Blob([response.data], {
          type: EXCEL_FILE_TYPE,
        });

        saveAs(blob, filename);

        return response;
      });
  }

  printCustomerOrdersWithProduct(productId) {
    return axios
      .get(API_URL + path + "/product/" + productId, {
        headers: authHeader(),
        responseType: "arraybuffer",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"]
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();

        const blob = new Blob([response.data], {
          type: EXCEL_FILE_TYPE,
        });

        saveAs(blob, filename);

        return response;
      });
  }

  getCollectionsReport(fromDate, toDate) {
    return axios
      .get(API_URL + path + "/collections-report", {
        headers: authHeader(),
        responseType: "arraybuffer",
        params: { fromDate: fromDate, toDate: toDate },
      })
      .then((response) => {
        let filename = response.headers["content-disposition"]
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();

        const blob = new Blob([response.data], {
          type: EXCEL_FILE_TYPE,
        });

        saveAs(blob, filename);

        return response;
      });
  }
}

export default new CustomerOrderRequest();
