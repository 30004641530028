import React from "react";
import {
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { getTotalAmount } from "../../../../util/CustomerOrderUtils";
import { dateToDayMonthAndTime } from "../../../../util/DateUtils";

const useStyles = makeStyles({
  table: {
    padding: "none",
  },

  tableContainer: {
    maxHeight: window.screen.height - 430,
    width: "100%",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

export const printDate = (stringDate) => {
  var date = new Date(stringDate);

  var dd = date.getDate();
  if (dd < 10) {
    dd = "0" + dd;
  }

  var mm = date.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }

  var yy = date.getFullYear();
  yy = yy.toString().substring(2, 4);

  return dd + "/" + mm + "/" + yy;
};

export const printState = (state) => {
  if (state === "PENDING") {
    return "Pendiente";
  } else if (state === "COMPLETED") {
    return "Completado";
  } else if (state === "CANCELLED") {
    return "Cancelado";
  }
};

function ShipmentOrdersTable(props) {
  const { dataSelected, setOpenDetail, setOpenShipmentOrderDetail } = props;
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell key={"ID"} align="left">
              ID
            </StyledTableCell>
            <StyledTableCell key={"Fecha"} align="right">
              Fecha
            </StyledTableCell>
            <StyledTableCell key={"Cliente"} align="right">
              Cliente
            </StyledTableCell>
            <StyledTableCell key={"Monto Total"} align="right">
              Monto Despachado
            </StyledTableCell>
            <StyledTableCell key={"# Articulos"} align="right">
              # Articulos
            </StyledTableCell>
            <StyledTableCell key={"Barrio"} align="right">
              Barrio
            </StyledTableCell>
            <StyledTableCell key={"Fecha de Entrega"} align="right">
              Fecha de Entrega
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSelected.map((row, index) => (
            <TableRow key={row.id} hover>
              <StyledTableCell padding="checkbox" align="center">
                <Tooltip title="Ver Detalle">
                  <IconButton
                    onClick={() => {
                      setOpenShipmentOrderDetail(index);
                    }}
                    size="small"
                  >
                    <ZoomInIcon />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.id}
              </StyledTableCell>
              <StyledTableCell align="right">
                {printDate(row.requestDate)}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.customer.nameAndSurname}
              </StyledTableCell>
              <StyledTableCell align="right">
                {"$" + Math.round(getTotalAmount(row))}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.customerOrderDetailsList.length}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.customer.zone.neighborhood}
              </StyledTableCell>
              <StyledTableCell align="right">
                {dateToDayMonthAndTime(row.estimatedDeliveryDate)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ShipmentOrdersTable;
