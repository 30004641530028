import React from "react";
import {
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { dateToDayMonthYear } from "../../../util/DateUtils";

const useStyles = makeStyles({
  table: {
    padding: "none",
  },

  tableContainer: {
    maxHeight: window.screen.height - 410,
    width: "100%",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

export const printState = (state) => {
  if (state === "PENDING") {
    return "Pendiente";
  } else if (state === "COMPLETED") {
    return "Completado";
  }
};

function SupplierOrdersTable(props) {
  const { selectedOrders, setOpenEditOrders } = props;
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell key={"ID"} align="left">
              ID
            </StyledTableCell>
            <StyledTableCell key={"Fecha"} align="right">
              Fecha
            </StyledTableCell>
            <StyledTableCell key={"Cliente"} align="right">
              Proveedor
            </StyledTableCell>
            <StyledTableCell key={"# Articulos"} align="right">
              # Articulos
            </StyledTableCell>
            <StyledTableCell key={"Estado"} align="right">
              Estado
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedOrders.map((order, index) => (
            <TableRow key={order.id} hover>
              <StyledTableCell padding="checkbox" align="center">
                <Tooltip title="Ver Detalle">
                  <IconButton
                    onClick={() => {
                      setOpenEditOrders(order.id);
                    }}
                    size="small"
                  >
                    <ZoomInIcon />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {order.id}
              </StyledTableCell>
              <StyledTableCell align="right">
                {dateToDayMonthYear(order.requestDate)}
              </StyledTableCell>
              <StyledTableCell align="right">
                {order.supplier.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                {order.supplierOrderDetails.length}
              </StyledTableCell>
              <StyledTableCell align="right">
                {printState(order.state)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SupplierOrdersTable;
