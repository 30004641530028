import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const StockChart = (props) => {
  const { selectedProducts, stockRecords } = props;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const data = [];
    stockRecords.forEach((stockRecord) => {
      const selectedIndex = selectedProducts.findIndex(
        (reg) => reg.id === stockRecord.productId
      );

      if (selectedIndex < 0) {
        return;
      }

      const dataRecordIndex = data.findIndex(
        (reg) => reg.date === stockRecord.date
      );

      if (dataRecordIndex >= 0) {
        data[dataRecordIndex].quantity += stockRecord.quantity;
      } else {
        data.push({
          date: stockRecord.date,
          quantity: stockRecord.quantity,
        });
      }

      data.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
    });

    setChartData(data);
  }, [selectedProducts, stockRecords]);

  return (
    <Paper>
      <Typography align="center">Stock por día [$]</Typography>
      <BarChart
        width={800}
        height={400}
        data={chartData}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="quantity" fill="#00923F" />
      </BarChart>
    </Paper>
  );
};

export default StockChart;
