import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const titles = ["Productos"];

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: window.screen.height - 350,
    overflow: "auto",
  },
});

const HeaderTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 14,
  },
}))(TableCell);

function ProductTable(props) {
  const { products, selectedProducts, setSelectedProducts } = props;
  const classes = useStyles();

  const handleCheckChange = (value) => {
    const currentIndex = selectedProducts.indexOf(value);
    const newChecked = [...selectedProducts];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedProducts(newChecked);
  };

  const handleCheckAllRows = () => {
    if (products.length === selectedProducts.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products);
    }
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table stickyHeader>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <HeaderTableCell padding="checkbox">
              <Checkbox
                checked={
                  products.length === selectedProducts.length &&
                  products.length > 0
                }
                onChange={handleCheckAllRows}
              />
            </HeaderTableCell>
            {titles.map((title, index) => (
              <HeaderTableCell key={index} align="left">
                {title}
              </HeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedProducts.includes(product)}
                  onChange={() => handleCheckChange(product)}
                />
              </TableCell>
              <TableCell align="left">{product.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default ProductTable;
