import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL } from "../util/Constants";

const path = "supplierOrder";

class SupplierOrderRequest {
  get(fromDate, toDate) {
    return axios.get(API_URL + path, {
      headers: authHeader(),
      params: { fromDate: fromDate, toDate: toDate },
    });
  }

  post(supplierOrders) {
    return axios.post(API_URL + path, supplierOrders, {
      headers: authHeader(),
    });
  }

  getStates() {
    return axios.get(API_URL + path + "/states", {
      headers: authHeader(),
    });
  }

  getSuppliers() {
    return axios.get(API_URL + "supplier", {
      headers: authHeader(),
    });
  }

  registerStockEntry(supplierOrderId, data) {
    return axios.post(
      API_URL + path + "/" + supplierOrderId + "/stock_entry",
      data,
      {
        headers: authHeader(),
      }
    );
  }

  completeOrder(supplierOrderId) {
    return axios.post(
      API_URL + path + "/" + supplierOrderId + "/complete",
      null,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new SupplierOrderRequest();
