import React, { useState } from "react";
import { Button, makeStyles, Divider, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { EXCEL_FILE_TYPE } from "../../../util/Constants";
import UploadIcon from "@material-ui/icons/Publish";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileExchange from "../../../services/FileExchange";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  divider: {
    height: 10,
  },
}));

const SupplierMaster = () => {
  const classes = useStyles();
  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  const uploadHandler = (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);

    event.target.value = null; //asi permite subir archivos consecutivamente

    const path = "supplier/upload";
    FileExchange.upload(data, path)
      .then((response) => {
        setsnackbarMessage("OK");
        setsnackbarSeverity("success");
        setsnackbarOpen(true);
      })
      .catch((error) => {
        setsnackbarMessage("" + error + " - " + error.response.data.message);
        setsnackbarSeverity("error");
        setsnackbarOpen(true);
      });
  };

  const downloadHandler = (event) => {
    const path = "supplier/download";
    FileExchange.download(path)
      .then((response) => {
        setsnackbarMessage("OK");
        setsnackbarSeverity("success");
        setsnackbarOpen(true);
      })
      .catch((error) => {
        setsnackbarMessage("" + error);
        setsnackbarSeverity("error");
        setsnackbarOpen(true);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  return (
    <div className={classes.paper}>
      <div>
        <input
          accept={[EXCEL_FILE_TYPE]}
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={uploadHandler}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            endIcon={<UploadIcon />}
          >
            Subir Maestro de Proveedores
          </Button>
        </label>
      </div>
      <Divider className={classes.divider} />
      <div>
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={downloadHandler}
          endIcon={<DownloadIcon />}
        >
          Descargar Maestro de Proveedores
        </Button>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SupplierMaster;
