import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomerOrderRequest from "../../../services/CustomerOrdersRequest";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

export default function CustomerOrdersPerProduct(props) {
  const classes = useStyles();
  const { openCOPP, setOpenCOPP, productList } = props;
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleClose = () => {
    setOpenCOPP(false);
  };

  const handlePrintCustomerOrdersWithProduct = (event) => {
    CustomerOrderRequest.printCustomerOrdersWithProduct(selectedProduct.id);
  };

  return (
    <Dialog open={openCOPP} onClose={handleClose} fullWidth maxWidth="md">
      <div style={{ padding: "0px 20px 20px" }}>
        <Grid container spacing={2}>
          <Grid container item xs={12} justify="center" spacing={2}>
            <DialogTitle>Obtener pedidos que llevan artículo</DialogTitle>
          </Grid>
          <Grid container justify="center" spacing={2}>
            <Autocomplete
              id="products_box"
              options={productList}
              getOptionLabel={(product) => product.description}
              style={{ width: 500 }}
              value={selectedProduct}
              onChange={(event, product) => setSelectedProduct(product)}
              renderInput={(params) => (
                <TextField {...params} label="Artículo" variant="outlined" />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              onClick={handlePrintCustomerOrdersWithProduct}
            >
              obtener resultado
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
