import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL } from "../util/Constants";

const path = "shipment";

export var fromDate = new Date();
fromDate.setDate(fromDate.getDate() - 700);

export var toDate = new Date();
toDate.setDate(toDate.getDate() + 7);

class ShipmentRequest {
  getData(fromDate, toDate) {
    return axios.get(API_URL + path, {
      headers: authHeader(),
      params: { fromDate: fromDate, toDate: toDate },
    });
  }

  getShipmentById(id) {
    return axios.get(API_URL + path + "/" + id, {
      headers: authHeader(),
    });
  }

  modifyShipmentData(shipment, sendEmailOption) {
    return axios.post(API_URL + path, shipment, {
      headers: authHeader(),
      params: { sendEmailOption },
    });
  }

  deleteShipmentById(id) {
    return axios.delete(API_URL + path + "/" + id, {
      headers: authHeader(),
    });
  }
}

export default new ShipmentRequest();
