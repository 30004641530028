import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const titles = [
  "Artículo",
  "Solicitado",
  "Ingresado Previamente",
  "Pendiente",
  "Ingreso Actual",
];

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: window.screen.height - 500,
  },
});

const HeaderTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 14,
  },
}))(TableCell);

function SupplierOrderEditTable(props) {
  const { orderToEdit, orderDetailsList, setOrderDetailsList } = props;
  const classes = useStyles();

  const handleStockEntryChange = (event, orderDetails) => {
    event.persist();

    setOrderDetailsList((prevState) => {
      let updatedOrderDetailsList = [...prevState];

      updatedOrderDetailsList.find(
        (reg) => reg.id === orderDetails.id
      ).stockEntry = event.target.value;

      return updatedOrderDetailsList;
    });
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table stickyHeader>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {titles.map((title, index) => (
              <HeaderTableCell key={index} align="center">
                {title}
              </HeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderDetailsList.map((orderDetails) => (
            <TableRow key={orderDetails.id}>
              <TableCell align="center">
                {orderDetails.product.description}
              </TableCell>
              <TableCell align="center">
                {orderDetails.requestedQuantity}
              </TableCell>
              <TableCell align="center">
                {orderDetails.deliveredQuantity}
              </TableCell>
              <TableCell align="center">
                {orderDetails.requestedQuantity -
                  orderDetails.deliveredQuantity}
              </TableCell>
              <TableCell align="center">
                {orderToEdit.state === "COMPLETED" ? (
                  ""
                ) : (
                  <TextField
                    name="stockEntry"
                    value={
                      orderDetails.stockEntry === undefined
                        ? 0
                        : orderDetails.stockEntry
                    }
                    size="small"
                    align="right"
                    type="number"
                    onChange={(event) =>
                      handleStockEntryChange(event, orderDetails)
                    }
                    style={{ width: 60 }}
                    InputProps={{
                      style: { fontSize: 14 },
                      inputProps: {
                        min: 0,
                        style: { textAlign: "center" },
                      },
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default SupplierOrderEditTable;
