import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  list: {
    overflow: "auto",
    maxHeight: window.screen.height - 400,
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

const DraggableOrderList = (props) => {
  const {
    selectedDate,
    updatedAssignedOrders,
    setUpdatedAssignedOrders,
    routeData,
    setRouteData,
    appMap,
  } = props;
  const classes = useStyles();

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const orderedItems = reorder(
      updatedAssignedOrders,
      result.source.index,
      result.destination.index
    );

    setUpdatedAssignedOrders(orderedItems);
    setRouteData(null);

    if (appMap.getSource("routeData")) {
      appMap.removeLayer("route");
      appMap.removeSource("routeData");
    }
  };

  const printTime = (ISOtime) => {
    const date = new Date(ISOtime);
    var hours = date.getHours();
    var isPm = hours > 12;
    var append = "";

    if (isPm) {
      append = " PM";
      hours = hours - 12;
    } else {
      append = " AM";
    }

    hours = hours.toString();
    if (hours.length < 2) {
      hours = "0" + hours;
    }

    var minutes = date.getMinutes().toString();
    if (minutes.length < 2) {
      minutes = "0" + minutes;
    }

    return hours + ":" + minutes + append;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <RootRef rootRef={provided.innerRef}>
            <List className={classes.list}>
              {updatedAssignedOrders.map((order, index) => (
                <Draggable
                  key={order.id}
                  draggableId={`${order.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <ListItem
                      dense
                      button
                      ContainerComponent="li"
                      ContainerProps={{ ref: provided.innerRef }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ListItemText
                        secondary={
                          order.estimatedDeliveryDate !== null
                            ? printTime(order.estimatedDeliveryDate)
                            : null
                        }
                      >
                        {index + 1}
                        {" - "}
                        <b>{order.customer.nameAndSurname}</b>
                        <br />
                        <b>
                          {order.deliveryNode == null
                            ? order.customer.address
                            : "PE" +
                              order.deliveryNode.id +
                              " - " +
                              order.deliveryNode.address}
                        </b>
                      </ListItemText>
                      <ListItemSecondaryAction></ListItemSecondaryAction>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableOrderList;
