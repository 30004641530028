import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL, PDF_FILE_TYPE, EXCEL_FILE_TYPE } from "../util/Constants";

const path = "customer";

class CustomerRequest {
  getAllCustomers() {
    return axios.get(API_URL + path, {
      headers: authHeader(),
    });
  }
}

export default new CustomerRequest();
