import React, { useState, useEffect } from "react";
import {
  AppBar,
  IconButton,
  Typography,
  makeStyles,
  Slide,
  Toolbar,
  Grid,
  Button,
  Box,
  Divider,
  Snackbar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import CartIcon from "@material-ui/icons/ShoppingCart";
import Alert from "@material-ui/lab/Alert";
import SimpleFilter, {
  getSelectSimpleOptions,
  getSelectInnerOptions,
} from "../../../../shared/Filters/SimpleFilter";
import ShipmentOrdersTable from "./ShipmentOrdersTable";
import ShipmentOrdersDetail from "./ShipmentOrdersDetail";
import { getShipmentCities } from "../ShipmentsTable";
import { printDate } from "../../client-orders/OrdersTable";
import ShipmentRequest from "../../../../services/ShipmentRequest";
import CustomerOrderRequest from "../../../../services/CustomerOrdersRequest";
import { getTotalAmount } from "../../../../util/CustomerOrderUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  subtitle: {
    variant: "h6",
    align: "center",
  },
  button: {
    width: 150,
  },
}));

function getDataSelected(data, neighborhoodsSelected, articlesSelected) {
  var dataSelected = data;

  //Neighborhoods Filter
  if (neighborhoodsSelected.length > 0) {
    dataSelected = dataSelected.filter((row) =>
      neighborhoodsSelected.includes(row.customer.zone.neighborhood)
    );
  }

  //Article Filter
  if (articlesSelected.length > 0) {
    dataSelected = dataSelected.filter((row) => {
      for (const detail of row.customerOrderDetailsList) {
        if (articlesSelected.includes(detail.product.description)) {
          return true;
        }
      }
      return false;
    });
  }

  return dataSelected;
}

function ConfirmShipment(props) {
  const classes = useStyles();
  const { openShipment, setOpenConfirmShipment, fetchShipmentData } = props;

  const [shipmentOrders, setShipmentOrders] = useState([]);
  const [neighborhoodsSelected, setNeighborhoodsSelected] = useState([]);
  const [articlesSelected, setArticlesSelected] = useState([]);
  const [openShipmentOrderDetail, setOpenShipmentOrderDetail] = useState(-1);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [sendEmailOption, setSendEmailOption] = useState(true);

  const [snackbarOpen, setsnackbarOpen] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("success");
  const [snackbarMessage, setsnackbarMessage] = useState("");

  useEffect(() => {
    setShipmentOrders([]);

    if (openShipment !== undefined) {
      ShipmentRequest.getShipmentById(openShipment.id).then((response) => {
        if (response.status === 200) {
          setShipmentOrders(response.data.customerOrders);
        }
      });
    }

    setSendEmailOption(true);
  }, [openShipment]);

  const handleCloseConfirmShipment = () => {
    setOpenConfirmShipment(undefined);
    fetchShipmentData();
  };

  const neighborhoodsOptions = getSelectSimpleOptions(
    shipmentOrders,
    "customer.zone.neighborhood"
  );

  const articlesOptions = getSelectInnerOptions(
    shipmentOrders,
    "customerOrderDetailsList",
    "product.description"
  );

  const handleNeighborhoodChange = (event) => {
    setNeighborhoodsSelected(event.target.value);
  };

  const handleArticleChange = (event) => {
    setArticlesSelected(event.target.value);
  };

  const handleClearFilters = () => {
    setNeighborhoodsSelected([]);
    setArticlesSelected([]);
  };

  const handleConfirmShipment = () => {
    setOpenConfirmDialog(false);

    const modifiedShipment = { ...openShipment, state: "COMPLETED" };

    ShipmentRequest.modifyShipmentData(modifiedShipment, sendEmailOption)
      .then((response) => {
        setOpenConfirmShipment({ ...response.data });
        handleCloseConfirmShipment();
      })
      .catch((error) => {
        setsnackbarMessage("" + error + " - " + error.response.data.message);
        setsnackbarSeverity("error");
        setsnackbarOpen(true);
      });
  };

  const handlePrintCustomerOrders = (event) => {
    CustomerOrderRequest.printTickets(shipmentOrders);
  };

  const getShipmentTotalAmount = () => {
    return shipmentOrders
      .map((order) => getTotalAmount(order))
      .reduce((a, b) => a + b, 0);
  };

  const getShipmentAmountInCash = () => {
    return shipmentOrders
      .filter(
        (order) =>
          order.paymentMethod !== undefined &&
          order.paymentMethod !== null &&
          order.paymentMethod.description === "EFECTIVO"
      )
      .map((order) => getTotalAmount(order))
      .reduce((a, b) => a + b, 0);
  };

  const getShipmentAmountInOtherPaymentMethods = () => {
    return shipmentOrders
      .filter(
        (order) =>
          order.paymentMethod !== undefined &&
          order.paymentMethod !== null &&
          order.paymentMethod.description !== "EFECTIVO"
      )
      .map((order) => getTotalAmount(order))
      .reduce((a, b) => a + b, 0);
  };

  var openThisConfirmation = false;
  if (openShipment !== undefined) {
    openThisConfirmation = true;
  } else {
    openThisConfirmation = false;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={openThisConfirmation}
        onClose={handleCloseConfirmShipment}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.AppBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseConfirmShipment}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Confirmar Despacho
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container style={{ marginTop: 30, padding: 40 }} spacing={2}>
          <Grid container item xs={12} spacing={2}>
            {openShipment !== undefined && (
              <Grid
                container
                item
                xs={9}
                alignItems="flex-end"
                justify="space-around"
              >
                <Typography align="center">
                  <b>Fecha</b>
                  <br />
                  {printDate(openShipment.shippingDate)}
                </Typography>
                <Typography align="center">
                  <b>Ciudades</b>
                  <br />
                  {getShipmentCities(openShipment)}
                </Typography>
                <Typography align="center">
                  <b>Monto Total</b>
                  <br />$ {Math.round(getShipmentTotalAmount())}
                </Typography>
                <Typography align="center">
                  <b>Monto Efectivo</b>
                  <br />$ {Math.round(getShipmentAmountInCash())}
                </Typography>
                <Typography align="center">
                  <b>Monto Otros</b>
                  <br />$ {Math.round(getShipmentAmountInOtherPaymentMethods())}
                </Typography>
              </Grid>
            )}

            <Grid container item xs={3} alignItems="center" justify="flex-end">
              <Button
                className={classes.button}
                color="secondary"
                variant="contained"
                endIcon={<CartIcon />}
                onClick={() => setOpenConfirmDialog(true)}
                disabled={
                  openShipment !== undefined && openShipment.state !== "PENDING"
                }
              >
                Confirmar
                <br />
                Despacho
              </Button>
              <Box m={1} />
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                endIcon={<PrintIcon />}
                onClick={handlePrintCustomerOrders}
              >
                Imprimir
                <br />
                Todos
              </Button>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            item
            xs={6}
            justify="space-around"
            alignItems="center"
          >
            <SimpleFilter
              label="Articulo"
              value={articlesSelected}
              onChange={handleArticleChange}
              filterOptions={articlesOptions}
              selectedOptions={articlesSelected}
            />
            <SimpleFilter
              label="Barrio"
              name="customer.zone.neighborhood"
              value={neighborhoodsSelected}
              onChange={handleNeighborhoodChange}
              filterOptions={neighborhoodsOptions}
              selectedOptions={neighborhoodsSelected}
            />
            <Button variant="text" color="primary" onClick={handleClearFilters}>
              Borrar Filtros
            </Button>
          </Grid>
          <Grid container item xs={12}>
            <ShipmentOrdersTable
              dataSelected={getDataSelected(
                shipmentOrders,
                neighborhoodsSelected,
                articlesSelected
              )}
              data={shipmentOrders}
              setData={setShipmentOrders}
              setOpenShipmentOrderDetail={setOpenShipmentOrderDetail}
            />
            <ShipmentOrdersDetail
              openShipment={openShipment}
              setOpenShipment={setOpenConfirmShipment}
              openDetail={openShipmentOrderDetail}
              setOpenDetail={setOpenShipmentOrderDetail}
              shipmentOrders={getDataSelected(
                shipmentOrders,
                neighborhoodsSelected,
                articlesSelected
              )}
              setShipmentOrders={setShipmentOrders}
            />
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <div style={{ padding: 20 }}>
          <DialogTitle>{`¿Confirmar el despacho? `}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Una vez confirmado, no se podrá editar el despacho y los pedidos
            </DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendEmailOption}
                  onChange={() => setSendEmailOption((prevState) => !prevState)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Enviar emails"
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleConfirmShipment}>
              Confirmar
            </Button>
            <Button color="primary" onClick={() => setOpenConfirmDialog(false)}>
              Volver
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default ConfirmShipment;
